import React, { useState, useEffect } from "react";
import VerifyUserModal from "../../components/VerifyUserModal/VerifyUserModal";
import IntroSection from "./IntroSection/IntroSection";
import VideoSection from "./VideoSection/VideoSection";
import TestimonialSection from "./TestimonialSection/TestimonialSection";
import Steps from "./Steps/Steps";
import RentVision from "./RentVision/RentVision";
import "./home.css";
import SEOTags from "../../utils/SEOTags";
import { LatestNews } from "./LatestNews/LatestNews";

export default function Home({ verifyUser = false }) {
  const [showVerifyModal, setShowVerifyModal] = useState(verifyUser);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);

  return (
    <main className="home-main-container">
      <SEOTags
        title={"Vakansa"}
        description={
          "Hyr och hyr ut alla möjliga lokaler på tider som passar dig. På Vakansa kan lokaler hyras i antingen förstahand eller andrahand per timme, dag, vecka eller månad."
        }
        canonicalUrl={"/"}
        ogImage={"/images/logo/meta-logo.jpg"}
      />
      <IntroSection />
      <VideoSection />
      <Steps />
      <RentVision />
      <TestimonialSection />
      <LatestNews/>
      {showVerifyModal && <VerifyUserModal setShowModal={setShowVerifyModal} />}
    </main>
  );
}
