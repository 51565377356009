import "./pagination.css";

export default function Pagination({
  currentPage,
  totalPages,
  onPageChange,
  ...props
}) {
  const maxPageButtons = 5;

  const startPage = Math.max(1, currentPage - Math.floor(maxPageButtons / 2));
  const endPage = Math.min(totalPages, startPage + maxPageButtons - 1);
  const displayedPageRange = [startPage, endPage];


  return (
    <div>
      {totalPages > 1 && (
        <div className="pagination-container">
          <button
            aria-label="pagination-arrow-back"
            className="pagination-arrow"
            onClick={() => onPageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            <svg
              viewBox="0 0 384 512"
              fill="currentColor"
              height="1em"
              width="0.5em"
              {...props}
            >
              <path d="M380.6 81.7c7.9 15.8 1.5 35-14.3 42.9L103.6 256l262.7 131.4c15.8 7.9 22.2 27.1 14.3 42.9s-27.1 22.2-42.9 14.3l-320-160C6.8 279.2 0 268.1 0 256s6.8-23.2 17.7-28.6l320-160c15.8-7.9 35-1.5 42.9 14.3z" />
            </svg>
          </button>

          <div>
            {Array.from(
              {
                length: displayedPageRange[1] - displayedPageRange[0] + 1,
              },
              (_, index) => {
                const pageNumber = displayedPageRange[0] + index;
                return (
                  <button
                    key={pageNumber}
                    onClick={() => onPageChange(pageNumber)}
                    className={currentPage === pageNumber ? "active" : ""}
                  >
                    {pageNumber}
                  </button>
                );
              }
            )}
          </div>

          <button
            aria-label="pagination-arrow-forward"
            className="pagination-arrow"
            onClick={() => onPageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            <svg
              viewBox="0 0 384 512"
              fill="currentColor"
              height="1em"
              width="0.5em"
              {...props}
            >
              <path d="M3.4 81.7c-7.9 15.8-1.5 35 14.3 42.9L280.5 256 17.7 387.4c-15.8 7.9-22.2 27.1-14.3 42.9s27.1 22.2 42.9 14.3l320-160c10.8-5.4 17.7-16.5 17.7-28.6s-6.8-23.2-17.7-28.6l-320-160c-15.8-7.9-35-1.5-42.9 14.3z" />
            </svg>
          </button>
        </div>
      )}
    </div>
  );
}
