import React from "react";
import "./imageNotFoundSmall.css"
import { ReactComponent as EyeSlashIcon } from "../../assets/icons/eye-slash.svg";

function ImageNotFoundSmall({ height, width, borderRadius, size = "default" }) {
  const iconSize =
    size === "large" ? "12rem" : size === "small" ? "6rem" : "8rem"; // 8rem is default
  const fontSize =
    size === "large" ? "2.8rem" : size === "small" ? "1.5rem" : "2rem"; // 2rem is default

  return (
    <div className="image-not-found-small"
      height={height}
      width={width}
      borderRadius={borderRadius}
      $iconSize={iconSize}
      $fontSize={fontSize}
    >
      <EyeSlashIcon alt="Icon-of-a-unslashed-eye" />
      <span>Bild inte tillgänglig</span>
    </div>
  );
}

export default ImageNotFoundSmall;
