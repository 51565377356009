import React from "react";
import { Link } from "react-router-dom";
import RoundedButton from "../Buttons/RoundedButton/RoundedButton";
import "./confirmationModal.css";

function ConfirmationModal({
  heading,
  text,
  linkTo = "/",
  linkToAd = "", // /lediga-lokaler/:id
  setShowModal,
  error = false,
  note = "",
}) {
  return error ? (
    <>
      <div className="confirmation-modal" error={error}>
        <h2>{heading}</h2>
        <p>{text}</p>
        <div
          className="confirmation-modal-btn-row"
          onClick={() => setShowModal(false)}
        >
          <RoundedButton
            label={"Okej"}
            className="confirmation-modal-btn"
          ></RoundedButton>
        </div>
      </div>
      <div
        className="confirmation-modal-backdrop"
        onClick={() => setShowModal(false)}
      />
    </>
  ) : (
    <>
      <div className="confirmation-modal">
        <h2>{heading}</h2>
        <p>{text}</p>
        {note && <span>*{note}</span>}
        <div className="confirmation-modal-btn-row">
          {/* Only show "Se annons" link if linkToAd property if passed */}
          {linkToAd && (
            <Link
              to={linkToAd}
              className="confirmation-modal-btn confirmation-modal-btn-white"
            >
              Se annons
            </Link>
          )}
          <Link
            to={linkTo}
            className="confirmation-modal-btn"
            data-testid="okay-btn"
          >
            {linkToAd ? "Klar" : "Okej"}
          </Link>
        </div>
        <button
          onClick={() => setShowModal(false)}
          className="confirmation-modal-close-btn"
        >
          <img src="/images/icons/close.svg" alt="close-icon-button" />
        </button>
      </div>
      <div
        className="confirmation-modal-backdrop"
        onClick={() => setShowModal(false)}
      />
    </>
  );
}

export default ConfirmationModal;
