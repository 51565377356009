import React from "react";
import "./unitTypeSection.css";
import UnitTypeCard from "./UnitTypeCard/UnitTypeCard";
import office from "../../../assets/spaces/office.webp";
import meeting from "../../../assets/spaces/meeting.webp";
import dance from "../../../assets/spaces/dance-studio.webp";
import event from "../../../assets/spaces/festlokal.webp";

export default function UnitTypeSection({ listings }) {

  return (
    <section className={`unit-type-section`}>
      <div className="unit-outer">
        <h3>Populära lokaltyper</h3>
        <UnitTypeCard
          title={"Kontorslokaler"}
          imgSrc={office}
          imgAlt={"Bild på ett kontor"}
          href="/lediga-lokaler/allakommuner/kontorslokal-kontorshotell&co-working"
        />
        <UnitTypeCard
          title={"Möteslokaler"}
          imgSrc={meeting}
          imgAlt={"Bild på en samlingslokal"}
          href="/lediga-lokaler/allakommuner/m%C3%B6teslokal-matsal&k%C3%B6k-biograf-teaterlokal"
        />
        <UnitTypeCard
          title={"Idrotts/danslokaler"}
          imgSrc={dance}
          imgAlt={"Bild på en dansstudio"}
          href="/lediga-lokaler/allakommuner/idrott&danslokal"
        />
        <UnitTypeCard
          title={"Eventlokaler"}
          imgSrc={event}
          imgAlt={"Bild på en eventlokal"}
          href="/lediga-lokaler/allakommuner/eventlokal"
        />
      </div>
    </section>
  );
}
