import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import AdPreviewContext from "../../../../context/AdPreviewContext";
import axios from "axios";
import { backend } from "../../../../backend";
import { logAxiosError } from "../../../../logging/error";
import WarningModal from "../../../../components/WarningModal/WarningModal";
import { useCallbackPrompt } from "../../../../context/useCallbackPrompt";
import { Helmet } from "react-helmet-async";
import Carousel from "../../../Ad/sections/Carousel/Carousel";
import SimplifiedBooking from "../../../Ad/sections/SimplifiedBooking/SimplifiedBooking";
import Heading from "../../../Ad/sections/Heading/Heading";
import Info from "../../../Ad/sections/Info/Info";
import Description from "../../../Ad/sections/Description/Description";
import Facilities from "../../../Ad/sections/Facilities/Facilities";
import RoundedButton from "../../../../components/Buttons/RoundedButton/RoundedButton";
import "./adPreview.css"

function AdPreview(props) {
  // eslint-disable-next-line no-unused-vars
  const [coordinates, setCoordinates] = useState(null);


  // Set to true when going back to form, this triggers
  // useEffect that disables prompt and navigates back
  const [goBack, setGoBack] = useState(false);

  // Warn users when leaving that their ad is not saved
  // enable and disable the prompt with this state
  const [showPrompt, setShowPrompt] = useState(false);
  const {
    showPrompt: showWarningModal,
    confirmNavigation,
    cancelNavigation,
  } = useCallbackPrompt(showPrompt);

  const {
    adPreviewInfo: { info, hasChanged },
    setAdPreviewInfo,
  } = useContext(AdPreviewContext);

  const location = useLocation();
  const navigate = useNavigate();

  const getCoordinates = async () => {
    try {
      const { data } = await axios.get(backend("/api/utilities/geolocate"), {
        params: {
          address: info?.address,
          city: info?.city,
          zip_code: info?.zip_code,
        },
      });

      if (data.status) {
        setCoordinates({
          lat: data.latitude,
          lng: data.longitude,
        });
      }
    } catch (error) {
      logAxiosError(error);
      // TODO: notify user?
    }
  };

  useEffect(() => {
    setShowPrompt(hasChanged);
    getCoordinates();

    // eslint-disable-next-line
  }, [hasChanged]);

  useEffect(() => {
    if (!goBack || showPrompt) return;

    // Navigate back to previous route
    navigate(location.state.prevRoute, { state: { from: "preview" } });

    // eslint-disable-next-line
  }, [goBack]);

  const navigateBackHandler = () => {
    // Disable prompt
    setShowPrompt(false);

    // Navigate back to form (in useEffect, wait to disable prompt)
    setGoBack(true);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" }); // scrolls to top on first render
  }, []);

  /**
   * Get all available price types for the ad. If the ad
   * does not have any prices. Say that price can be discussed
   * @param {object} ad - Ad object fetched from database
   */

  return (
    <>
      <Helmet>
        <title>Förhandsvisning</title>
      </Helmet>
      <div className="ad-component">
        <div id="ad-preview-nav">
          <RoundedButton
            size={"sm"}
            color={"green"}
            label={"Tillbaka"}
            onClick={navigateBackHandler}
          />
          <p>
            <i>*Det här är en förhandsvy av din annons</i>
          </p>
        </div>
        <div id="ad-page-preview-body">
          <>
            <Carousel ad={info} />
            <SimplifiedBooking ad={info} />
            <div id="ad-page-info-prev">
              <Heading ad={info} />
              <Info ad={info} />
              <Description ad={info} />
              <Facilities ad={info} />
            </div>
            <div className="watermark">förhandsvy</div>
          </>
        </div>

      </div>
      {showWarningModal && (
        <WarningModal
          heading="Lämna utan att spara"
          text="Är du säker att du vill lämna sidan? Din annons kommer ej att sparas."
          confirmBtnText="Lämna"
          onCancel={cancelNavigation}
          onConfirm={() => {
            setAdPreviewInfo({ info: {}, images: [], hasChanged: false });
            confirmNavigation();
          }}
        />
      )}
    </>
  );
}



export default AdPreview;
