import React, { useEffect, useState } from "react";
import AdCard from "../AdCard/AdCard";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { backend } from "../../../../backend";
import { logAxiosError } from "../../../../logging/error";
import { useAuth } from "../../../../context/AuthContext";
import useCheckIfMobile from "../../../../customHooks/useCheckIfMobile";
import "./myAds.css"

function MyAds() {
  const [ads, setAds] = useState([]);
  const [adCount, setAdCount] = useState({});
  const [adFilter, setAdFilter] = useState("all");
  const navigate = useNavigate();
  const auth = useAuth();
  const isMobile = useCheckIfMobile();

  useEffect(() => {
    const fetchMyAds = async () => {
      try {
        const { data } = await axios.get(
          backend("/api/listings/user/all-listings"),
          {
            params: {
              token: localStorage.getItem("token"),
            },
          }
        );

        if (data.status) {
          setAds(data.ads);
        } else {
          // no ads
          setAds([]);
        }
      } catch (error) {
        logAxiosError(error);
        setAds([]);
        if (error.response?.status === 401) {
          // invalid JWT token -> log out and return to landing page
          auth.logout();
        }
      }
    };

    fetchMyAds();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    updateAdCount(ads);
  }, [ads]);

  const updateAdCount = (ads = []) => {
    const initialValue = {
      all: 0,
      active: 0,
      inactive: 0,
    };

    // If ads array is not empty then set the
    // ad count for all, active and inactive ads
    if (ads) {
      let numberOfAds = ads.reduce(
        (total, ad) =>
          ad.active
            ? { ...total, active: total.active + 1 }
            : { ...total, inactive: total.inactive + 1 },
        initialValue
      );

      numberOfAds.all = numberOfAds.active + numberOfAds.inactive;
      setAdCount(numberOfAds);
    } else {
      // set ad count to all 0's
      setAdCount(initialValue);
    }
  };

  /**
   * Get the name of the user, or the organizations name
   */
  const getUserName = () => {
    const user = JSON.parse(localStorage.getItem("user"));

    const firstname = user?.first_name;
    const organization = user?.organization;

    if (firstname) {
      return firstname;
    } else {
      return organization;
    }
  };

  /**
   * Get number of total, active and inactive ads
   * @param {Object[]} [ads] - An array of ad objects
   */

  const filterAdsHandler = (ads) => {
    if (adFilter === "active") {
      return ads.filter((ad) => ad.active);
    } else if (adFilter === "inactive") {
      return ads.filter((ad) => !ad.active);
    } else {
      return ads;
    }
  };

  return (
    <>
      <div className="my-ads-header-section">
        <h1 data-testid="greeting-header">Hej {getUserName()}</h1>
        {isMobile ? <div className="separation-line" /> : null}
        <div className="card-filter">
          <button
            aria-label="all-ads"
            onClick={() => setAdFilter("all")}
            className={adFilter === "all" ? "filter-button-active" : undefined}
          >
            Alla {adCount.all}
          </button>
          <button
            aria-label="active-ads"
            onClick={() => setAdFilter("active")}
            className={
              adFilter === "active" ? "filter-button-active" : undefined
            }
          >
            Aktiva {adCount.active}
          </button>
          <button
            aria-label="inactive-ads"
            onClick={() => setAdFilter("inactive")}
            className={
              adFilter === "inactive" ? "filter-button-active" : undefined
            }
          >
            Inaktiva {adCount.inactive}
          </button>
        </div>
        {isMobile ? <div className="separation-line" /> : null}
      </div>
      <div className="my-ads-card-section" data-testid="ad-section">
        <div className="create-ad-card"
          data-testid="create-ad-card"
          onClick={() => navigate("/mina-sidor/skapa-annons")}
        >
          <img src="/images/icons/plus-circle.svg" alt="plus with a circle" />
          <p>Skapa annons</p>
        </div>
        {filterAdsHandler(ads).map((ad) => (
          <AdCard key={ad._id} ad={ad} setAds={setAds} />
        ))}
      </div>
    </>
  );
}

export default MyAds;
