import React, { useState, useEffect } from 'react';
import SquareButton from '../../../../../components/Buttons/SquareButton/SquareButton';
import Checkbox from '../../../../../components/Checkbox/Checkbox';
import TextButton from '../../../../../components/Buttons/TextButton/TextButton';
import municipalityDb from '../../../../../assets/municipalities.json';
import "./municipalityModal.css";

export default function MunicipalityModal({ onRequestClose, selectedMunicipality, setSelectedMunicipality }) {
  const [searchText, setSearchText] = useState('');

  const handleMunicipalityChange = (municipality) => {
    const lowerCasedMunicipality = municipality.toLowerCase();
    if (selectedMunicipality.includes(lowerCasedMunicipality)) {
      setSelectedMunicipality(selectedMunicipality.filter(item => item !== lowerCasedMunicipality));
    } else {
      setSelectedMunicipality([...selectedMunicipality, lowerCasedMunicipality]);
    }
  }

  const handleClearButtonClick = () => {
    setSelectedMunicipality([]);
    sessionStorage.removeItem('selectedMunicipality');
  }

  const handleSaveButtonClick = () => {
    sessionStorage.setItem('selectedMunicipality', JSON.stringify(selectedMunicipality));
    onRequestClose();
  };



  useEffect(() => {
    // Disable scrolling when modal is open
    document.body.classList.add("modal-open");

    // Remove class when modal is closed
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, []);

  const filteredMunicipalities = municipalityDb.municipalities.filter(municipalityLabel =>
    municipalityLabel.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <>
      <div className="municipality-modal-container">
        <input
          className="municipality-search"
          type='text'
          value={searchText}
          placeholder="Sök kommun"
          onChange={(event) => setSearchText(event.target.value)}
        />
        <svg
          viewBox="0 0 1024 1024"
          fill="currentColor"
          height="1em"
          width="1em"
          id="rooms-types-modal-close-btn"
          onClick={onRequestClose}
        >
          <path d="M685.4 354.8c0-4.4-3.6-8-8-8l-66 .3L512 465.6l-99.3-118.4-66.1-.3c-4.4 0-8 3.5-8 8 0 1.9.7 3.7 1.9 5.2l130.1 155L340.5 670a8.32 8.32 0 00-1.9 5.2c0 4.4 3.6 8 8 8l66.1-.3L512 564.4l99.3 118.4 66 .3c4.4 0 8-3.5 8-8 0-1.9-.7-3.7-1.9-5.2L553.5 515l130.1-155c1.2-1.4 1.8-3.3 1.8-5.2z" />
          <path d="M512 65C264.6 65 64 265.6 64 513s200.6 448 448 448 448-200.6 448-448S759.4 65 512 65zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
        </svg>

        <div className="searchResults-modal-checkboxes">
          {filteredMunicipalities.map((municipalityLabel, index) => (
            <Checkbox
              key={index}
              label={municipalityLabel}
              isChecked={selectedMunicipality.includes(municipalityLabel.toLowerCase())}
              onChange={() => handleMunicipalityChange(municipalityLabel)}
            />
          ))}
        </div>

        <div id="searchResults-modal-buttons">
          <TextButton label='Rensa' onClick={handleClearButtonClick} />
          <SquareButton size='sm' label='Spara' onClick={handleSaveButtonClick} />
        </div>
      </div>
      <div className="municipality-modal-backdrop" onClick={onRequestClose} />
    </>
  );
}
