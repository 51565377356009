import React, { useState } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useNavigate } from "react-router-dom";
import { Image, Placeholder } from "cloudinary-react";
import ImageNotFoundSmall from "../../../../components/ImageNotFoundSmall/ImageNotFoundSmall";
import axios from "axios";
import { backend } from "../../../../backend";
import { logAxiosError } from "../../../../logging/error";
import WarningModal from "../../../../components/WarningModal/WarningModal";
import { useAuth } from "../../../../context/AuthContext";
import { createSEOUrl } from "../../../../utils/createSEOUrl";
import "./adCard.css"

function AdCard({ ad, setAds, favourite = false, props }) {
  const [showWarningModal, setShowWarningModal] = useState(false);
  const navigate = useNavigate();
  const auth = useAuth();

  const removeFavouriteHandler = async (e, ad) => {
    e.stopPropagation();

    try {
      const { data } = await axios.post(backend("/api/favourite/remove"), {
        token: localStorage.getItem("token"),
        ad_id: ad._id,
      });

      if (data.status) {
        setAds((ads) => {
          return ads.filter((currentAd) => currentAd._id !== ad._id);
        });
      }
    } catch (error) {
      logAxiosError(error);
      // TODO: notify user
      if (error.response?.status === 401) {
        // invalid JWT token -> log out and return to landing page
        auth.logout();
      }
    }
  };

  const editAdHandler = (e, ad) => {
    e.stopPropagation();
    navigate(`/mina-sidor/redigera-annons/${ad._id}`);
  };

  const activateAdHandler = async (e, ad) => {
    e.stopPropagation();

    try {
      const { data } = await axios.patch(backend("/api/listings/activate"), {
        token: localStorage.getItem("token"),
        ad_id: ad._id,
      });

      if (data.status) {
        setAds((ads) => {
          return ads.map((currentAd) =>
            currentAd._id === ad._id
              ? { ...currentAd, active: true }
              : currentAd
          );
        });
        ReactTooltip.rebuild();
      }
    } catch (error) {
      logAxiosError(error);
      // TODO: notify user
      if (error.response?.status === 401) {
        // invalid JWT token -> log out and return to landing page
        auth.logout();
      }
    }
  };

  const inactivateAdHandler = async (e, ad) => {
    e.stopPropagation();

    try {
      const { data } = await axios.patch(backend("/api/listings/inactivate"), {
        token: localStorage.getItem("token"),
        ad_id: ad._id,
      });

      if (data.status) {
        setAds((ads) => {
          return ads.map((currentAd) =>
            currentAd._id === ad._id
              ? { ...currentAd, active: false }
              : currentAd
          );
        });

        ReactTooltip.rebuild();
      }
    } catch (error) {
      logAxiosError(error);
      // TODO: notify user
      if (error.response?.status === 401) {
        // invalid JWT token -> log out and return to landing page
        auth.logout();
      }
    }
  };

  const deleteAdHandler = (e) => {
    e.stopPropagation();
    setShowWarningModal(true);
  };

  const deleteAd = async (ad) => {
    try {
      const { data } = await axios.delete(backend("/api/listings/delete"), {
        data: {
          token: localStorage.getItem("token"),
          ad_id: ad._id,
        },
      });

      if (data.status) {
        setAds((ads) => ads.filter((currentAd) => currentAd._id !== ad._id));
      }
    } catch (error) {
      logAxiosError(error);
      // TODO: notify user
    }

    // Hide warning modal
    setShowWarningModal(false);
  };

  const cardButtons = () => {
    if (favourite) {
      return (
        <>
          <button
            aria-label="remove favourite ad"
            key="favorite-remove"
            className="ad-card-button"
            onClick={(e) => removeFavouriteHandler(e, ad)}
          >
            <svg
              viewBox="0 0 24 24"
              fill="currentColor"
              height="1.2em"
              width="1.2em"
              {...props}
            >
              <path d="M13.41 12l4.3-4.29a1 1 0 10-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 00-1.42 1.42l4.3 4.29-4.3 4.29a1 1 0 000 1.42 1 1 0 001.42 0l4.29-4.3 4.29 4.3a1 1 0 001.42 0 1 1 0 000-1.42z" />
            </svg>
          </button>
        </>
      );
    }

    if (ad.active) {
      return (
        <>
          <button
            aria-label="edit ad"
            key="active-edit"
            className="ad-card-button"
            onClick={(e) => editAdHandler(e, ad)}
            data-tooltip-html="Redigera"
            data-tooltip-id="my-tooltip"
            data-tooltip-place="top"
          >
            <svg
              viewBox="0 0 16 16"
              fill="currentColor"
              height="1em"
              width="1em"
              {...props}
            >
              <path
                fill="currentColor"
                d="M13.5 0a2.5 2.5 0 012 4l-1 1L11 1.5l1-1c.418-.314.937-.5 1.5-.5zM1 11.5L0 16l4.5-1 9.25-9.25-3.5-3.5L1 11.5zm10.181-5.819l-7 7-.862-.862 7-7 .862.862z"
              />
            </svg>
          </button>
          <button
            aria-label="inactivate ad"
            data-testid="ad-card-inactivate"
            key="active-inactivate"
            className="ad-card-button"
            onClick={(e) => inactivateAdHandler(e, ad)}
            data-tooltip-html="Inaktivera"
            data-tooltip-id="my-tooltip"
            data-tooltip-place="top-end"
          >
            <svg
              viewBox="0 0 24 24"
              fill="currentColor"
              height="1.2em"
              width="1.2em"
              {...props}
            >
              <path d="M13.41 12l4.3-4.29a1 1 0 10-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 00-1.42 1.42l4.3 4.29-4.3 4.29a1 1 0 000 1.42 1 1 0 001.42 0l4.29-4.3 4.29 4.3a1 1 0 001.42 0 1 1 0 000-1.42z" />
            </svg>
          </button>
        </>
      );
    } else {
      return (
        <>
          <button
            key="inactive-edit"
            aria-label="edit ad"
            className="ad-card-button"
            onClick={(e) => editAdHandler(e, ad)}
            data-tooltip-html="Redigera"
            data-tooltip-id="my-tooltip"
            data-tooltip-place="top-end"
          >
            <svg
              viewBox="0 0 16 16"
              fill="currentColor"
              height="1em"
              width="1em"
              {...props}
            >
              <path
                fill="currentColor"
                d="M13.5 0a2.5 2.5 0 012 4l-1 1L11 1.5l1-1c.418-.314.937-.5 1.5-.5zM1 11.5L0 16l4.5-1 9.25-9.25-3.5-3.5L1 11.5zm10.181-5.819l-7 7-.862-.862 7-7 .862.862z"
              />
            </svg>
          </button>
          <button
            aria-label="inactivate ad"
            key="inactive-activate"
            className="ad-card-button"
            onClick={(e) => activateAdHandler(e, ad)}
            data-tooltip-html="Aktivera"
            data-tooltip-id="my-tooltip"
            data-tooltip-place="top-end"
          >
            <svg
              fill="currentColor"
              viewBox="0 0 16 16"
              height="1.3em"
              width="1.3em"
              {...props}
            >
              <path d="M10.97 4.97a.75.75 0 011.07 1.05l-3.99 4.99a.75.75 0 01-1.08.02L4.324 8.384a.75.75 0 111.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 01.02-.022z" />
            </svg>
          </button>
          <button
            aria-label="delete inactive ad"
            data-testid="ad-card-inactive-delete"
            key="inactive-delete"
            className="ad-card-button"
            onClick={(e) => deleteAdHandler(e)}
            data-tooltip-html="Radera"
            data-tooltip-id="my-tooltip"
            data-tooltip-place="top-end"
          >
            <svg
              viewBox="0 0 448 512"
              fill="currentColor"
              height="1em"
              width="1em"
              {...props}
            >
              <path d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0h120.4c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64s14.3-32 32-32h96l7.2-14.3zM32 128h384v320c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16v224c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16v224c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16v224c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z" />
            </svg>
          </button>
        </>
      );
    }
  };

  return (
    <>
      <div className="ad-card-component"
        data-testid="ad-card"
        onClick={() =>
          navigate(
            `/lediga-lokaler/${createSEOUrl(ad.municipality)}/${createSEOUrl(
              ad.type_of_room
            )}/${createSEOUrl(ad.address)}/${ad._id}`
          )
        }
      >
        <ReactTooltip id="my-tooltip" className="ad-card-tooltip" />

        <div className="ad-card-button-group">
          {cardButtons() /* Load buttons according to the ad's state */}
        </div>

        <div className="ad-card-image">
          {ad.images.length > 0 ? (
            <Image
              alt={ad.address}
              cloudName="vakansa"
              publicId={`listings/${ad._id}/${ad.images[0]}`}
              responsive
              responsiveUseBreakpoints="true"
              dpr="auto"
              height="240"
              width="auto"
              crop="fill"
              style={{ width: "100%" }}
            >
              {/* <Transformation crop="fill" gravity="center" /> */}
              <Placeholder type="predominant" />
            </Image>
          ) : (
            <ImageNotFoundSmall size="large" />
          )}
          {!favourite ? (
            <div className="ad-card-status">
              {ad.active ? (
                <p className="ad-card-status-active">Aktiv</p>
              ) : (
                <p className="ad-card-status-inactive">Inaktiv</p>
              )}
            </div>
          ) : null}
        </div>

        <div className="ad-card-body">
          <div className="ad-card-heading">
            <h2>{ad.address}</h2>
            <p>
              {ad.city}, {ad.municipality} kommun
            </p>
          </div>

          <div className="ad-card-info">
            <p>{ad.type_of_room}</p>
            <p>
              {/* Show capacity if workplaces is 0, else show nothing */}
              {ad?.workspaces
                ? `${ad.workspaces} sittplatser`
                : `${ad.area} kvm`}
            </p>
            <p>Antal visningar: {ad.clicks}</p>
          </div>
        </div>
      </div>
      {showWarningModal ? (
        <WarningModal
          heading="Radera annons?"
          text={
            <>
              Är du saker på att du vill radera <b>{ad.address}</b> ?
            </>
          }
          setShowModal={setShowWarningModal}
          onConfirm={() => deleteAd(ad)}
          confirmBtnText="Radera"
        />
      ) : null}
    </>
  );
}

export default AdCard;
