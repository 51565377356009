import React from "react";
import RoundedLinkButton from "../../components/Buttons/RoundedLinkButton/RoundedLinkButton";
import "./notFound.css";
import { Helmet } from "react-helmet-async";
import { backend } from "../../backend";
function NotFound() {
  return (
    <div className="not-found">
      <Helmet>
        <title>Hoppsan! | Vakansa</title>
        <meta
          name="description"
          content="Sidan du försöker nå verkar inte existera."
        />
        <link rel="canonical" href={backend("/404")} />
        <meta property="og:image" content="/images/logo/meta-logo.jpg" />
        <meta name="robots" content="noindex" />
      </Helmet>
      <h1>Hoppsan! Något gick fel...</h1>
      <p>Sidan du försöker nå verkar inte existera.</p>
      <div className="not-found-btns">
        <RoundedLinkButton
          label={"Startsidan"}
          color={"green"}
          size={"lg"}
          href={"/"}
        />
        <RoundedLinkButton
          label={"Lediga lokaler"}
          color={"green"}
          size={"lg"}
          href={"/lediga-lokaler"}
        />
        <RoundedLinkButton
          label={"Kostnadsfria förslag"}
          color={"green"}
          size={"lg"}
          href={"/lokalforslag"}
        />
        <RoundedLinkButton
          label={"Support"}
          color={"white"}
          size={"lg"}
          href={"/kontakta-oss"}
        />
      </div>
    </div>
  );
}

export default NotFound;
