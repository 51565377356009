import React, { useRef, forwardRef } from 'react';
import './filterDropdownButton.css';
import arrowUp from '../../../assets/icons/arrow-up.svg';

const FilterDropdownButton = forwardRef(({ label, dropdownContent, isActive, isDropdownVisible, toggleDropdown }, ref) => {
  const dropdownRef = useRef();
  const buttonRef = useRef();

  const handleClick = () => {
    toggleDropdown();
  };

  // Combine the forwarded ref with our internal refs
  React.useImperativeHandle(ref, () => ({
    contains: (target) => {
      return (
        (buttonRef.current && buttonRef.current.contains(target)) ||
        (dropdownRef.current && dropdownRef.current.contains(target))
      );
    }
  }));

  return (
    <div className={`filter-dropdown-container`}>
      <div
        className={`filter-dropdown-button ${isActive ? 'active' : ''}`}
        ref={buttonRef}
        onClick={handleClick}
      >
        <div className={`button-content`}>
          {label}
          <img
            className={`arrow-icon ${isDropdownVisible ? 'flipped' : ''}`}
            src={arrowUp}
            alt={isDropdownVisible ? 'Arrow pointing down' : 'Arrow pointing up'}
          />
        </div>
      </div>
      {isDropdownVisible && (
        <div className="dropdown" ref={dropdownRef}>
          {dropdownContent}
        </div>
      )}
    </div>
  );
});

export default FilterDropdownButton;