import "./blogCard.css";
import { Link } from "react-router-dom";
import { createSEOUrl } from "../../../utils/createSEOUrl";
import { formatDate } from "../../../utils/formatDate";
import imgPlaceholder from "../../../assets/logo/green-v.png"

export const BlogCard = ({ posts }) => {
  const extractThumbnail = (content) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, "text/html");
    const figureImg = doc.querySelector("figure img"); 

    return figureImg ? figureImg.src : imgPlaceholder; 
  };

  return (
    <div className="blog-card-container">
      {posts.length > 0 ? (
        posts.map((blogposts) => {
          const thumbnail = extractThumbnail(blogposts.content);
          return (
            <Link
              className="blog-card-item"
              to={`/blogg/${createSEOUrl(blogposts.title)}`}
              key={blogposts.id}
            >
              {thumbnail && (
                <img
                  src={thumbnail}
                  alt={blogposts.title}
                  className="blog-card-thumbnail"
                />
              )}
              <p className="blog-card-title">{blogposts.title}</p>
              <b>{formatDate(blogposts.date)}</b>
            </Link>
          );
        })
      ) : (
        <p>Inga blogginlägg att visa just nu.</p>
      )}
    </div>
  );
};
