import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Sidebar from "./components/Sidebar/Sidebar";
import MyAds from "./components/MyAds/MyAds";
import CreateAd from "./components/CreateAd/CreateAd";
import Settings from "./components/Settings/Settings";
import Support from "./components/Support/Support";
import EditAd from "./components/EditAd/EditAd";
import { Helmet } from "react-helmet-async";
import FeedbackModal from "./components/FeedbackButton/FeedbackModal";
import "./dashboard.css";
import { backend } from "../../backend";

function Dashboard() {
  const { page } = useParams();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, [page]);

  const loadContentHandlers = () => {
    switch (page) {
      case "annonser":
        return (
          <>
            <Helmet>
              <title>Mina annonser | Vakansa</title>
              <meta
                name="description"
                content="Hantera och uppdatera dina annonser enkelt på Vakansa. Få en översikt över dina aktiva och tidigare annonser."
              />
              <link rel="canonical" href={backend("/mina-sidor/annonser")}/>
              <meta property="og:image" content="/images/logo/meta-logo.jpg" />
              <meta property="og:title" content="Mina sidor på Vakansa" />
              <meta
                property="og:description"
                content="Hantera och uppdatera dina annonser enkelt på Vakansa. Få en översikt över dina aktiva och tidigare annonser."
              />
              <meta name="robots" content="noindex" />
            </Helmet>
            <div className="breadcrumb-top">
              <p id="dashboard-breadcrumbs"></p>
              <FeedbackModal />
            </div>
            <MyAds />
          </>
        );
      case "skapa-annons":
        return (
          <>
            <Helmet>
              <title>Skapa annons </title>
            </Helmet>
            <p id="dashboard-breadcrumbs">
              <Link to="/mina-sidor/annonser">Mina sidor</Link>&middot;
              <Link to="/mina-sidor/annonser">Annonser</Link>&middot;
              <Link to="/mina-sidor/skapa-annons">Skapa annons</Link>
            </p>
            <CreateAd />
          </>
        );
      case "redigera-annons":
        return (
          <>
            <Helmet>
              <title>Redigera annons </title>
            </Helmet>
            <p id="dashboard-breadcrumbs">
              <Link to="/mina-sidor/annonser">Mina sidor</Link>&middot;
              <Link to="/mina-sidor/annonser">Mina annonser</Link>&middot;
              <Link to="/mina-sidor/annonser">Redigera annons</Link>
            </p>
            <EditAd />
          </>
        );
      case "support":
        return (
          <>
            <Helmet>
              <title>Support </title>
            </Helmet>
            <p id="dashboard-breadcrumbs">
              <Link to="/mina-sidor/annonser">Mina sidor</Link>&middot;
              <Link to="/mina-sidor/support">Support</Link>
            </p>
            <Support />
          </>
        );
      case "installningar":
        return (
          <>
            <Helmet>
              <title>Inställningar </title>
            </Helmet>
            <p id="dashboard-breadcrumbs">
              <Link to="/mina-sidor/annonser">Mina sidor</Link>&middot;
              <Link to="/mina-sidor/installningar">Inställningar</Link>
            </p>
            <Settings />
          </>
        );
      default:
      // Redirect to 404 page
      // return <Navigate to="/sidan-kunde-hittas" />;
    }
  };

  return (
    <div className="dashboard-page">
      <Sidebar />
      <div className="dashboard-content">{loadContentHandlers()}</div>
    </div>
  );
}

export default Dashboard;
