import axios from "axios";
import { backend } from "../../../backend";
import { logAxiosError } from "../../../logging/error";

export const fetchFilteredAds = async ({
  filterParams,
  currentPage,
  adsPerPage,
  sortingOption,
  setId,
  setTotalPages,
  setTotalAds,
  searchTerm,
  setAllAds,
  setIsLoading
}) => {
  try {
    const { data } = await axios.get(backend("/api/listings/filter"), {
      params: {
        ...filterParams,
        page: currentPage,
        limit: 0,
        sort: sortingOption,
        search: searchTerm
      },
    });
    setAllAds(data.ads);
    setTotalPages(Math.ceil(data.ads.length / adsPerPage));
    setTotalAds(data.ads.length); 

    // Calculate the start and end indices for pagination
    const startIndex = (currentPage - 1) * adsPerPage;
    const endIndex = startIndex + adsPerPage;

    // Paginate the ads
    setId(data.ads.slice(startIndex, endIndex)); 

  } catch (error) {
    logAxiosError(error);
  }
  finally {
    setIsLoading(false)
  }
};
