import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import useOnClickOutside from "../../../customHooks/useOnClickOutside";
import { useAuth } from "../../../context/AuthContext";
import "./mobileNav.css";

export default function MobileNav() {
  const [showNav, setShowNav] = useState(false);
  const ref = useRef();
  const auth = useAuth();
  useOnClickOutside(ref, () => setShowNav(false));

  return (
    <div className={`mobile-nav ${showNav ? "open" : ""}`} ref={ref}>
      <div className="toggle" onClick={() => setShowNav(!showNav)}>
        <span />
      </div>
      <div className="items">
        {auth.user ? (
          <>
            <Link to="/lediga-lokaler" onClick={() => setShowNav(!showNav)}>
              Lediga lokaler
            </Link>
            <Link to="/hyr-ut-lokal" onClick={() => setShowNav(!showNav)}>
              Hyr ut lokal
            </Link>
            <Link to="/om-oss" onClick={() => setShowNav(!showNav)}>
              Om oss
            </Link>
            <Link to="/fragor-och-svar" onClick={() => setShowNav(!showNav)}>
            Frågor och Svar
            </Link>
            <Link to="/blogg" onClick={() => setShowNav(!showNav)}>
              Blogg
            </Link>
            <hr />
            <Link
              to="/mina-sidor/annonser"
              onClick={() => setShowNav(!showNav)}
            >
              Mina sidor
            </Link>
            <button
              onClick={() => {
                auth.logout();
                setShowNav(!showNav);
              }}
            >
              Logga ut
            </button>
          </>
        ) : (
          <>
            <Link to="/lediga-lokaler" onClick={() => setShowNav(!showNav)}>
              Lediga lokaler
            </Link>
            <Link to="/hyr-ut-lokal" onClick={() => setShowNav(!showNav)}>
              Hyr ut lokal
            </Link>
            <Link to="/om-oss" onClick={() => setShowNav(!showNav)}>
              Om oss
            </Link>
            <Link to="/fragor-och-svar" onClick={() => setShowNav(!showNav)}>
            Frågor och Svar
            </Link>
            <Link to="/blogg" onClick={() => setShowNav(!showNav)}>
              Blogg
            </Link>
            <hr />
            <Link to="/logga-in" data-testid="navbar-login-btn">
            Logga in
                </Link>
          </>
        )}
      </div>
   
    </div>
  );
}
