import "./terms.css";
import SEOTags from "../../utils/SEOTags";
import DOMPurify from "dompurify";
import { gql, useQuery } from "@apollo/client";
import Spinner from "../../components/Spinner/Spinner";
// GraphQL query to get terms content
const GET_TERMS_CONTENT = gql`
  query GetTermsContent {
    allAnvandarvillkor {
      nodes {
        id
        title
        content
        date
      }
    }
  }
`;

function Terms() {
  // Apollo Client's useQuery hook to fetch data
  const { loading, error, data } = useQuery(GET_TERMS_CONTENT);

  // Loading and error handling for the query
  if (loading)
    return (
      <div className="terms-page-spinner-wrapper">
        <Spinner />
      </div>
    );
  if (error) return <p>Error loading terms data</p>;

  // Assuming there's only one node with terms content
  const termsContent = data.allAnvandarvillkor.nodes[0]?.content || "";

  return (
    <div className="terms-outer">
      <SEOTags
        title={"Användarvillkor"}
        description={
          "Dessa användarvillkor reglerar användningen av Tjänsterna (“Tjänsterna”, se vidare p.3) som tillhandahålls av Vakansa AB."
        }
        canonicalUrl={"/anvandarvillkor"}
        ogImage={"/images/logo/meta-logo.jpg"}
      />
      <div className="terms-inner">
        <div className="terms-page">
          <div
            className="docx-content"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(termsContent),
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default Terms;
