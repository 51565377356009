import React from "react";
import DOMPurify from "dompurify";
import "./blogPage.css";
import RoundedLinkButton from "../../components/Buttons/RoundedLinkButton/RoundedLinkButton";
import SEOTags from "../../utils/SEOTags";
import { createSEOUrl } from "../../utils/createSEOUrl";
import { formatDate } from "../../utils/formatDate";
import MostRecent from "./components/MostRecent/MostRecent";
import { gql, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import Spinner from "../../components/Spinner/Spinner";

const GET_ALL_BLOG_POSTS = gql`
  query GetAllBlogPosts {
    blogposts {
      nodes {
        id
        title
        content
        date
      }
    }
  }
`;

const BlogPage = () => {
  const { title: urlTitle } = useParams();
  const decodedTitle = decodeURIComponent(urlTitle).replace(/-/g, " "); // Decode and remove hyphens

  const { data, loading, error } = useQuery(GET_ALL_BLOG_POSTS);
  if (loading)
    return (
      <div className="blog-page-spinner-wrapper">
        <Spinner />
      </div>
    );
  if (error) return <p>Error loading blog post</p>;

  const post = data?.blogposts?.nodes.find(
    (post) =>
      post.title.trim().toLowerCase() === decodedTitle.trim().toLowerCase()
  );

  if (!post) return <p>No blog post found.</p>;

  const sliceContentForDescriptionTag = post.content.replace(
    /(<([^>]+)>)/gi,
    ""
  );

  const words = sliceContentForDescriptionTag.split(/\s+/);

  let slicedDescription = "";
  let totalLength = 0;

  for (const word of words) {
    if (totalLength + word.length + 1 <= 150) {
      slicedDescription += (slicedDescription ? " " : "") + word;
      totalLength += word.length + 1;
    } else {
      break;
    }
  }

  slicedDescription =
    slicedDescription.length > 0
      ? slicedDescription
      : sliceContentForDescriptionTag;

  const extractThumbnail = (content) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, "text/html");
    const figureImg = doc.querySelector("figure img");

    return figureImg ? figureImg.src : null;
  };

  const thumbnail = extractThumbnail(post.content);

  return (
    <div className="blogpage-outer">
      <SEOTags
        title={post.title + "   "}
        description={slicedDescription}
        canonicalUrl={`/blogg/${createSEOUrl(post.title)}`}
        ogImage={thumbnail}
      />
      <div className="blogpage-inner">
        <main className="blogpage-container">
          <h1 className="blogpage-header">{post.title}</h1>
          <span className="blogpage-date">{formatDate(post.date)}</span>
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(post.content),
            }}
          ></div>
          <RoundedLinkButton
            className="blogpage-back-btn"
            label={"Tillbaka"}
            href={"/blogg"}
            color="white"
            size="md"
          />
        </main>
        <MostRecent />
      </div>
    </div>
  );
};

export default BlogPage;
