import React from "react";
import "./subletting.css";
import SEOTags from "../../utils/SEOTags";

export default function Subletting() {
  const isMobile = window.innerWidth < 410;
  return (
    <div className="subletting-outer">
      <SEOTags
        title={"Hyra ut lokal i andrahand   "}
        description={
          "Det finns flera viktiga saker att tänka på när det gäller andrahandsuthyrning av lokaler för att säkerställa en smidig process."
        }
        canonicalUrl={"/andrahandsuthyrning"}
        ogImage={"/images/logo/meta-logo.jpg"}
      />
      <div className="subletting-inner">
        <main className={`subletting-container`}>
          <div className={`subletting-intro-content`}>
            <div className={`subletting-intro-text`}>
              <h1>Hyra ut lokal i andrahand</h1>

              {isMobile === true ? (
                <>
                  <h2>Att tänka på kring andrahands-</h2>
                  <h2>uthyrning av lokaler</h2>
                </>
              ) : isMobile === false ? (
                <h2>Att tänka på kring andrahandsuthyrning av lokaler</h2>
              ) : null}
              <p>
                Vi på Vakansa är främst en lokaldelningsplattform där just
                andrahandsuthyrningar görs. När du överväger att hyra en lokal i
                andra hand är det viktigt att upprätta ett andrahandskontrakt
                för att tydligt definiera villkoren. Andrahandsuthyrning är ett
                populärt alternativ för företag som har utrymmen som står
                oanvända, vilket gör det möjligt för dem att dela på kostnaderna
                och potentiellt undvika en kostsam flytt. Att hyra kontor i
                andra hand kan vara ett ekonomiskt alternativ för mindre företag
                som söker prisvärda lösningar.
              </p>
              <br />
              {isMobile === true ? (
                <>
                  <h3>Förstahands-</h3>
                  <h3>kontraktets betydelse</h3>
                </>
              ) : isMobile === false ? (
                <h3>Förstahandskontraktets betydelse</h3>
              ) : null}
              <p>
                Enligt svensk hyreslagstiftning är det förbjudet att tillåta
                handlingar av en andra- eller tredjepart som kan vara skadliga
                för hyresvärden. Vad som anses vara skadligt bedöms individuellt
                i varje enskilt fall. Till exempel, om lokalen ursprungligen
                hyrs som en lagerlokal, bör den även i andra hand användas för
                liknande ändamål för att undvika konflikter med hyresvärden.
                Detta gäller generellt alla typer av lokaler, och det är oftast
                smidigast att fortsätta med liknande verksamhet som lokalen
                redan är anpassad för.
              </p>
              <br />
              {isMobile === true ? (
                <>
                  <h3>Vikten av ett andrahands-</h3>
                  <h3>kontrakt</h3>
                </>
              ) : isMobile === false ? (
                <h3>Vikten av ett andrahandskontrakt</h3>
              ) : null}
              <p>
                Det är avgörande att formalisera avtalet genom ett skriftligt
                kontrakt, där villkor som ersättning och uppsägningstid kan
                fastställas av parterna eftersom dessa inte är reglerade av lag.
                Ett tydligt avtal hjälper till att förebygga missförstånd.
                Dessutom har andrahandshyresgästen ett indirekt besittningsskydd
                efter nio månaders hyra, vilket kan inkludera rätt till
                ersättning för kostnader i samband med en uppsägning, såvida
                detta inte undantas i avtalet. Många uthyrare väljer att
                exkludera detta skydd i avtalet för att undvika ekonomiskt
                ansvar vid eventuell uppsägning. Standardavtal finns
                tillgängliga online och kan vanligtvis användas utan att
                juridisk expertis behövs.
              </p>
              <br />
              <p>
                Behöver du hjälp med att se över eller skriva ett
                andrahandskontrakt?
              </p>
              <b>
                Hör av er till oss <a href="/kontakta-oss">här</a>
              </b>
            </div>{" "}
          </div>
        </main>{" "}
      </div>
    </div>
  );
}
