import React from "react";
import "./roundedButton.css";

export default function RoundedButton({
  label,
  color = '',
  size = '',
  onClick,
  disabled = false
  
}) {
  return (
    <button
      className={`rounded-button ${color} ${size}`}
      onClick={onClick}
      disabled={disabled}
    >
      {label}
    </button>
  );
}
