export function pluralizeRoomType(roomType) {
  
  switch (roomType) {
    case "ateljé":
      return "ateljér";
    case "behandlingsrum":
      return "behandlingsrum";
    case "eventlokal":
      return "eventlokaler";
    case "idrott&danslokal":
      return "idrott och danslokaler";
    case "klassrum":
      return "klassrum";
    case "kontorslokal":
      return "kontorslokaler";
    case "kontorshotell&co-working":
      return "kontorshotell och co-working lokaler";
    case "matsal&kök":
      return "matsalar och kök";
    case "musiksal":
      return "musiksalar";
    case "möteslokal":
      return "möteslokaler";
    case "pop-up":
      return "pop-up lokaler";
    case "studio":
      return "studios";
    case "teaterlokal":
      return "teaterlokaler";
    case "övrigt":
      return "övriga lokaler";
    case "biograf":
      return "biografer";
    case "kyrka":
      return "kyrkor";
    case "lager&logistik":
      return "lager och logistik lokaler"; 
    default:
      return roomType;
  }
}