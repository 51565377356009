export const buildQueryParams = (params) => {
    const queryParams = new URLSearchParams();

    if (params.selectedTime.length)
        queryParams.set("pristyp", params.selectedTime.join(","));
    if (params.selectedPriceRange.min !== null)
        queryParams.set("min-hyra", params.selectedPriceRange.min);
    if (params.selectedPriceRange.max !== null)
        queryParams.set("max-hyra", params.selectedPriceRange.max);
    if (params.selectedAreaRange.min !== null)
        queryParams.set("min-yta", params.selectedAreaRange.min);
    if (params.selectedAreaRange.max !== null)
        queryParams.set("max-yta", params.selectedAreaRange.max);

    if (
        params.selectedMoms.includes("true") &&
        !params.selectedMoms.includes("false")
    ) {
        queryParams.set("moms", "true");
    } else if (
        params.selectedMoms.includes("false") &&
        !params.selectedMoms.includes("true")
    ) {
        queryParams.set("moms", "false");
    } else {
        queryParams.delete("moms");
    }

    if (params.searchTerm) queryParams.set("q", params.searchTerm);
    if (params.page && params.page > 1) queryParams.set("p", params.page);

    return queryParams.toString();
};
