import React, { useState, useRef } from "react";
import { useAuth } from "../../../../context/AuthContext";
import axios from "axios";
import { backend } from "../../../../backend";
import { logAxiosError } from "../../../../logging/error";
import { Link } from "react-router-dom";
import "./support.css";
import { Helmet } from "react-helmet-async";

function Support(props) {
  const [message, setMessage] = useState("");
  const [responseObject, setResponseObject] = useState({
    message: "",
    error: false,
  });

  const { user } = useAuth();

  let btnRef = useRef();

  const submitFeedbackHandler = async (e) => {
    e.preventDefault();

    // Disable button when submitting
    btnRef.current.setAttribute("disabled", "disabled");

    try {
      const { data } = await axios.post(
        backend("/api/contact/general-feedback"),
        {
          name: user?.first_name ? user?.first_name : user?.organization,
          email: user?.email,
          message,
        }
      );

      setMessage(""); // clear the feedback message area upon succesful submit
      setResponseObject({
        message: data.msg,
        error: !data.status, // set error to true, if request fails
      });
    } catch (error) {
      logAxiosError(error);
      setResponseObject({
        message: error.response?.data?.msg,
        error: true,
      });
    }
  };

  const onChangeHandler = (e) => {
    setMessage(e.target.value);
    setResponseObject({});
  };

  return (
    <>
      <Helmet>
        <title>Support | Vakansa</title>
        <meta
          name="description"
          content="Behöver du hjälp? Kontakta oss med dina frågor, få svar på vanliga frågor, eller lämna feedback för att hjälpa oss förbättra Vakansa."
        />
         <link rel="canonical" href={backend("/mina-sidor/support")}/>
        <meta property="og:image" content="/images/logo/meta-logo.jpg" />
        <meta property="og:title" content="Support på Vakansa" />
        <meta
          property="og:description"
          content="Behöver du hjälp? Kontakta oss med dina frågor, få svar på vanliga frågor, eller lämna feedback för att hjälpa oss förbättra Vakansa."
        />
        <meta name="robots" content="noindex" />
      </Helmet>
      <div className="support-container">
        <h2>Support</h2>
        <p>
          Om du har några problem med tjänsten eller har frågor kan du nå vår
          kundtjänst via:
        </p>
        <div className="contact-info">
          <p>
            <svg
              viewBox="0 0 512 512"
              fill="currentColor"
              height="1em"
              width="1em"
              {...props}
            >
              <path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4l217.6 163.2c11.4 8.5 27 8.5 38.4 0l217.6-163.2c12.1-9.1 19.2-23.3 19.2-38.4 0-26.5-21.5-48-48-48H48zM0 176v208c0 35.3 28.7 64 64 64h384c35.3 0 64-28.7 64-64V176L294.4 339.2a63.9 63.9 0 01-76.8 0L0 176z" />
            </svg>

            <a href="mailto:it-support@vakansa.se">it-support@vakansa.se</a>
          </p>
        </div>
        <div className="support-questions">
          <h3>Vanliga Frågor</h3>
          <p className="text-muted">
            Svaret på vanliga frågor kan du hitta här:
          </p>
          <div className="support-links">
            <Link to="/fragor-och-svar/hyresvard">Hyresvärden</Link>
            <Link to="/fragor-och-svar/hyresgast">Hyresgästen</Link>
          </div>
        </div>
        <div className="support-feedback">
          <h3>Feedback</h3>
          <p className="text-muted">
            Varmt välkommen att lämna feedback eller förbättringsförslag nedan.
            Din åsikt är betydelsefull.
          </p>
          <form id="supportFeedbackForm" onSubmit={submitFeedbackHandler}>
            <textarea
              id="supportFeedbackTextarea"
              className="form-control"
              placeholder="Skriv ditt meddelande här"
              rows="6"
              value={message}
              onChange={onChangeHandler}
            />
            <div className="support-response">
              {responseObject.error ? (
                <p className="support-response-error">
                  {responseObject.message}
                  test
                </p>
              ) : responseObject.message ? (
                <p className="support-response-success">
                  {responseObject.message}
                </p>
              ) : (
                <p>&nbsp;</p> // Placeholder for spacing
              )}
            </div>
            <button ref={btnRef} disabled={!message}>
              Skicka
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export default Support;
