import React from "react";
import { Link } from "react-router-dom";
import "./confirmationModal.css";
import TextButton from "../../../../components/Buttons/TextButton/TextButton";

function ConfirmationModal({ setShowConfirmationModal }) {
  return (
    <>
      <div className="confirmation-modal-component">
        <h2>Aktivera ditt konto</h2>
        <p>Ett aktiveringsmejl har skickats till din e-postadress.</p>
        <br />
        <b>OBS! - Kolla din skräpkorg om du inte hittar mejlet</b>
        <br />
        <br />
        <br />
        <Link className="confirmation-button-link" to="/">
          Okej!
        </Link>
        <br />
        <br />
        <br />
        <p>
          Har du inte fått något mejl? &nbsp;
          <br />
          <TextButton
            size="xxs"
            color="green"
            label={"Klicka här!"}
            href={"/skicka-validering"}
          />
        </p>
      </div>
      <div
        className="confirmation-modal-backdrop"
        onClick={() => setShowConfirmationModal(false)}
      />
    </>
  );
}

export default ConfirmationModal;
