import React from "react";
import "./warningModal.css";

function WarningModal({
  heading,
  text,
  setShowModal,
  onCancel,
  onConfirm,
  cancelBtnText = "Avbryt",
  confirmBtnText = "Okej",
}) {
  const onCancelHandler = onCancel ? onCancel : () => setShowModal(false);
  const onConfirmHandler = onConfirm ? onConfirm : () => setShowModal(false);

  return (
    <>
      <div className="warning-modal-component">
        <h2>{heading}</h2>
        <p>{text}</p>
        <div className="warning-modal-btn-row">
          <button
            className="warning-modal-btn warning-modal-btn-cancel"
            onClick={onCancelHandler}
          >
            {cancelBtnText}
          </button>
          <button
            data-testid="confirm-btn-warningmodal"
            className="warning-modal-btn warning-modal-btn-confirm"
            onClick={onConfirmHandler}
          >
            {confirmBtnText}
          </button>
        </div>
        <button className="warning-modal-close-btn" onClick={onCancelHandler}>
          <img src="/images/icons/close.svg" alt="close-icon-button" />
        </button>
      </div>
      <div className="warning-modal-backdrop" onClick={onCancelHandler} />
    </>
  );
}

export default WarningModal;
