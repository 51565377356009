import React, { forwardRef } from "react";
import { MapContainer } from "react-leaflet";
import { GestureHandling } from "leaflet-gesture-handling";
import L from "leaflet";

L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);

export const MapContainerWithGestureHandling = forwardRef(({ ...props}, ref) => {
  return (
    <MapContainer
      {...props}
      ref={ref}
      maxZoom={18}
      gestureHandling={true}
      gestureHandlingOptions={{
        duration: 500,
        text: {
          touch: "Använd två fingrar för att flytta kartan",
          scroll: "Använd ctrl + scroll för att zooma kartan",
          scrollMac: "Använd \u2318 + scroll för att zooma kartan",
        },
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        textColor: "#ffffff",
      }}
    />
  );
});
