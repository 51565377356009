import React, { useState, useEffect } from "react";
import "./introSection.css";
import Society from "../../../assets/illustrations/hero.webp";
import ScalingButton from "../../../components/Buttons/ScalingButton/ScalingButton";
import ActiveButton from "../../../components/Buttons/ActiveButton/ActiveButton";
import RoomTypesModal from "../../AdPage/Dropdowns/SpaceDropdown/RoomTypesModal/RoomTypesModal";
import MunicipalityModal from "../../AdPage/Dropdowns/MunicipalityDropdown/MunicipalityModal/MunicipalityModal";
import { useNavigate, useParams } from "react-router-dom";

export default function IntroSection() {
  const getStoredFilters = (filterType, defaultValue) => {
    const storedFilters = sessionStorage.getItem(filterType);
    return storedFilters ? JSON.parse(storedFilters) : defaultValue;
  };

  const { municipality: pathMunicipality, type_of_room: pathTypeOfRoom } =
    useParams();
  const [selectedRoomTypes, setSelectedRoomTypes] = useState(() =>
    pathMunicipality
      ? pathMunicipality.split("-")
      : getStoredFilters("selectedRoomTypes", [])
  );
  const [selectedLocations, setSelectedLocations] = useState(() =>
    pathTypeOfRoom
      ? pathTypeOfRoom.split("-")
      : getStoredFilters("selectedMunicipality", [])
  );
  const navigate = useNavigate();
  const [isModalRoomTypesOpen, setIsModalRoomTypesOpen] = useState(false);
  const [isMunicipalityModalOpen, setIsMunicipalityModalOpen] = useState(false);
  const [isAllaKommunerActive, setIsAllaKommunerActive] = useState(false);
  const [isAllaLokalerActive, setIsAllaLokalerActive] = useState(false);

  const saveToStorage = (key, value) =>
    sessionStorage.setItem(key, JSON.stringify(value));
  const loadFromStorage = (key, setter) => {
    const data = sessionStorage.getItem(key);
    if (data) setter(JSON.parse(data));
  };

  useEffect(() => {
    loadFromStorage("selectedRoomTypes", setSelectedRoomTypes);
    loadFromStorage("selectedMunicipality", setSelectedLocations);
  }, []);

  useEffect(() => {
    const excludedMunicipalities = ["stockholm", "göteborg", "malmö"];
    const exludedRoomTypes = ["kontorslokal", "eventlokal", "möteslokal"];

    const allSelectedRoomTypesAreExcluded = selectedRoomTypes.every(
      (roomType) => exludedRoomTypes.includes(roomType)
    );

    const hasNonExcludedRoomType = selectedRoomTypes.some(
      (roomType) => !exludedRoomTypes.includes(roomType)
    );

    const allSelectedMunicipalitiesAreExcluded = selectedLocations.every(
      (municipality) => excludedMunicipalities.includes(municipality)
    );

    const hasNonExcludedMunicipality = selectedLocations.some(
      (municipality) => !excludedMunicipalities.includes(municipality)
    );

    setIsAllaKommunerActive(
      hasNonExcludedMunicipality && !allSelectedMunicipalitiesAreExcluded
    );
    setIsAllaLokalerActive(
      hasNonExcludedRoomType && !allSelectedRoomTypesAreExcluded
    );
  }, [selectedRoomTypes, selectedLocations]);

  const handleButtonClick = (label, data, setter, saver) => {
    const updatedData = data.includes(label)
      ? data.filter((item) => item !== label)
      : [...data, label];
    setter(updatedData);
    saveToStorage(saver, updatedData);
  };

  const handleSearchButtonClick = () => {
    const path =
      selectedLocations.length === 0 && selectedRoomTypes.length === 0
        ? "/lediga-lokaler"
        : `/lediga-lokaler${
            selectedLocations.length > 0
              ? `/${selectedLocations.join("-").toLowerCase()}`
              : "/allakommuner"
          }${
            selectedRoomTypes.length > 0
              ? `/${selectedRoomTypes.join("-").toLowerCase()}`
              : ""
          }`;
    navigate(path);
  };

  const handleOpenModal = (setter) => {
    setter(true);
  };

  return (
    <div className="intro-section">
      <div data-testid="home-intro-title" id="home-intro-title">
        <h1 data-testid="landingpage-header">
          Letar du lokal? <br />
          Vi hjälper dig!
          <br />
        </h1>
        <img
          src={Society}
          width="430"
          height="321"
          alt="Lite träd, några hus och en sjö"
        />
      </div>
      <div id="home-intro-filter-buttons">
        <p>Typ av lokal?</p>
        <div className="home-intro-btn-group">
          <div className="button-spacing">
            {[
              "Kontorslokal",
              "Eventlokal",
              "Möteslokal",
              "Andra\nlokaltyper",
            ].map((label, index) => (
              <ActiveButton
                key={index}
                label={
                  label === "Andra\nlokaltyper" ? (
                    <>
                      Andra
                      <br />
                      lokaltyper
                    </>
                  ) : (
                    label
                  )
                }
                size="md"
                isActive={
                  label === "Andra\nlokaltyper"
                    ? isAllaLokalerActive
                    : selectedRoomTypes.includes(label.toLowerCase())
                }
                onClick={() => {
                  if (label === "Andra\nlokaltyper") {
                    handleOpenModal(setIsModalRoomTypesOpen);
                  } else {
                    handleButtonClick(
                      label.toLowerCase(),
                      selectedRoomTypes,
                      setSelectedRoomTypes,
                      "selectedRoomTypes"
                    );
                  }
                }}
              />
            ))}
            {isModalRoomTypesOpen && (
              <RoomTypesModal
                isOpen={isModalRoomTypesOpen}
                onRequestClose={() => setIsModalRoomTypesOpen(false)}
                selectedRoomTypes={selectedRoomTypes}
                setSelectedRoomTypes={setSelectedRoomTypes}
              />
            )}
          </div>
        </div>
        <p>Var söker ni?</p>
        <div className="button-spacing">
          {["Stockholm", "Göteborg", "Malmö", "Andra kommuner"].map(
            (label, index) => (
              <ActiveButton
                key={index}
                label={label}
                size="md"
                isActive={
                  label === "Andra kommuner"
                    ? isAllaKommunerActive
                    : selectedLocations.includes(label.toLowerCase())
                }
                onClick={() => {
                  if (label === "Andra kommuner") {
                    handleOpenModal(setIsMunicipalityModalOpen);
                  } else {
                    handleButtonClick(
                      label.toLowerCase(),
                      selectedLocations,
                      setSelectedLocations,
                      "selectedMunicipality"
                    );
                  }
                }}
              />
            )
          )}
          {isMunicipalityModalOpen && (
            <MunicipalityModal
              isOpen={isMunicipalityModalOpen}
              onRequestClose={() => setIsMunicipalityModalOpen(false)}
              selectedMunicipality={selectedLocations}
              setSelectedMunicipality={setSelectedLocations}
            />
          )}
        </div>
        <div className="button-spacing-scaling">
          <ScalingButton
            label="Sök"
            color="white"
            size="md"
            onClick={handleSearchButtonClick}
          />
        </div>
      </div>
    </div>
  );
}
