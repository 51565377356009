import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { HelmetProvider } from "react-helmet-async";
import { ApolloProvider, ApolloClient, InMemoryCache } from "@apollo/client";

/// Initializing new ApolloClient
const client = new ApolloClient({
  uri: 'https://jyoti.templweb.com/graphql',
  cache: new InMemoryCache(),
})

const root = createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
    <BrowserRouter>
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </BrowserRouter>
    </ApolloProvider>
  </React.StrictMode>
);

reportWebVitals();
