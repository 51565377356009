import React, { useEffect, useState } from "react";
import "./priceDropdown.css";
import TextButton from "../../../../components/Buttons/TextButton/TextButton";

export default function PriceDropdown({
  selectedPriceRange,
  setSelectedPriceRange,
}) {
  const [minPrice, setMinPrice] = useState(selectedPriceRange.min || "");
  const [maxPrice, setMaxPrice] = useState(selectedPriceRange.max || "");

  const priceOptions = [
    100, 250, 500, 1000, 2500, 5000, 10000, 25000, 50000, 100000,
  ];

  useEffect(() => {
    setMinPrice(selectedPriceRange.min || "");
    setMaxPrice(selectedPriceRange.max || "");
  }, [selectedPriceRange]);

  const handleMinInputChange = (event) => {
    const value = event.target.value;
    setMinPrice(value);
    handlePriceRangeChange({
      min: value,
      max: maxPrice,
    });
  };

  const handleMaxInputChange = (event) => {
    const value = event.target.value;
    setMaxPrice(value);
    handlePriceRangeChange({
      min: minPrice,
      max: value,
    });
  };

  const handlePriceRangeChange = ({ min, max }) => {
    setSelectedPriceRange({ min, max });
    sessionStorage.setItem("selectedPriceRange", JSON.stringify({ min, max }));
  };

    const handleClearButtonClick = () => {
    setSelectedPriceRange({ min: null, max: null });
    setMinPrice("");
    setMaxPrice("");
    sessionStorage.removeItem("selectedPriceRange");
  };


  return (
    <div className="price-dropdown">
      <p>Kostnad (kr)</p>
      <div className="price-range-slider">
        <div className="price-slider-container">
          <div className="price-slider-price">
            <div>
              <select value={minPrice} onChange={handleMinInputChange}>
                <option value="">Min pris (kr)</option>
                {priceOptions.map((price) => (
                  <option key={price} value={price} disabled={maxPrice && price >= maxPrice}>
                    {price}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <select value={maxPrice} onChange={handleMaxInputChange}>
                <option value="">Max pris (kr)</option>
                {priceOptions.map((price) => (
                  <option key={price} value={price} disabled={minPrice !== "" && price <= minPrice}>
                    {price}
                  </option>
                ))}
              </select>
            </div>
          </div>
           <hr />
      <div className="price-button-wrapper">
        <TextButton label="Rensa" onClick={handleClearButtonClick} />
      </div>
        </div>
      </div>
    </div>
  );
}
