import React, { useState, useEffect } from "react";
import "./interestModal.css";
import close from "../../assets/icons/cancel.svg";
import RoundedLinkButton from "../Buttons/RoundedLinkButton/RoundedLinkButton";

export default function InterestModal() {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(true);
  }, []);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div role="banner" className={`interest-modal ${isOpen ? "open" : ""}`}>
      <div className="card-holder-inner">
        <img
          src={close}
          alt="close-icon"
          className="card-close-icon"
          onClick={toggleModal}
        />
        <p className="card-text">Hittar du inte det du söker?</p>
        <RoundedLinkButton
          label={"Få gratis lokal förslag"}
          color={"green"}
          size={"lg"}
          href={"/lokalforslag"}
        />
      </div>
    </div>
  );
}
