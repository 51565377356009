import React from "react";
import Landlord from "./Landlord/Landlord";
import Pricing from "./Pricing/Pricing";
import SEOTags from "../../utils/SEOTags";
import "./rentOutSpacePage.css"

function RentOutSpace() {
  return (
    <>
      <SEOTags
        title={"Hyr ut lokal   "}
        description={
          "Söker du en enkel och flexibel lösning för att hyra ut dina lediga lokaler i andrahand? Då har du kommit rätt!"
        }
        canonicalUrl={"/hyr-ut-lokal"}
        ogImage={"/images/logo/meta-logo.jpg"}
      />
      <main className="rent-out-space-outer">
        <Landlord />
        <Pricing />
      </main>
    </>
  );
}

export default RentOutSpace;
