import React from "react";
import Spinner from '../../../../components/Spinner/Spinner'
import "./loading.css"

function Loading({ text }) {
  return (
    <>
      <div className="loading-component">
        <Spinner />
        {text && <p>{text}</p>}
      </div>
      <div className="loading-backdrop" />
    </>
  );
}

export default Loading;
