import React, { useState } from "react";
import ResendValidation from "./sections/ResendValidation";
import SEOTags from "../../utils/SEOTags";

export default function ResendValidationPage() {
  const [serverError, setServerError] = useState("");
  return (
    <>
      <SEOTags
        title={"Skicka nytt valideringsmejl   "}
        description={
          "Begär ett nytt valideringsmejl genom att fylla i e-postadressen du angav vid skapandet av kontot"
        }
        canonicalUrl={"/skicka-validering"}
        ogImage={"/images/logo/meta-logo.jpg"}
      />
      <ResendValidation
        serverError={serverError}
        setServerError={setServerError}
      />
    </>
  );
}
