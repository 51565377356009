export const buildFilterParams = ({
  selectedRoomTypes,
  selectedMunicipality,
  selectedTime,
  selectedPriceRange,
  selectedAreaRange,
  selectedMoms,
}) => {
  const filterParams = {};

  if (selectedRoomTypes.length > 0)
    filterParams.type_of_room = selectedRoomTypes.join(",");
  if (selectedMunicipality.length > 0)
    filterParams.municipality = selectedMunicipality.join(",");
  if (selectedTime.length > 0) filterParams.priceTypes = selectedTime.join(",");

  // Use default value null for price and area

  filterParams.min_rent = selectedPriceRange.min;
  filterParams.max_rent = selectedPriceRange.max;
  filterParams.min_area = selectedAreaRange.min;
  filterParams.max_area = selectedAreaRange.max;

  if (selectedMoms.includes("true") && !selectedMoms.includes("false"))
    filterParams.vat_tax_required = "true";
  else if (selectedMoms.includes("false") && !selectedMoms.includes("true"))
    filterParams.vat_tax_required = "false";

  return filterParams;
};
