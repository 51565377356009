import React, { useState } from "react";
import RoundedButton from "../../components/Buttons/RoundedButton/RoundedButton";
import axios from "axios";
import { backend } from "../../backend";
import { useForm } from "react-hook-form";
import "./contactUs.css";
import SEOTags from "../../utils/SEOTags";

function ContactU() {
  const [buttonClicked, setButtonClicked] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = async (formData) => {
    try {
      await handleFormSubmit(formData);
      setButtonClicked(true);
    } catch (error) {
      console.error(error);
    }
  };

  const handleFormSubmit = async (formData) => {
    try {
      await axios.post(
        backend("/api/contact/general-feedback"),

        formData
      );
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <SEOTags
        title={"Kontakta oss   "}
        description={
          "Vänligen kontakta oss via formuläret nedan om du har frågor, feedback eller funderingar kring Vakansa!"
        }
        canonicalUrl={"/kontakta-oss"}
        ogImage={"/images/logo/meta-logo.jpg"}
      />
      <main className="contact-form-section">
        <form onSubmit={handleSubmit(onSubmit)}>
          <h1>Kontakta oss</h1>
          <p className="contact-form-subheading">
            Välkommen att höra av dig till oss om du har frågor, feedback eller
            andra tankar kring Vakansa!
          </p>
          <div className="contact-form-name">
            <p>Ditt namn*</p>
            <input
              aria-label="name"
              {...register("name", { required: true })}
            />

            {errors.name && <span>Vänligen ange ett namn</span>}
          </div>
          <div className="contact-form-email-number">
            <div>
              <p>E-postadress*</p>
              <input
                aria-label="email"
                type="email"
                {...register("email", { required: true })}
              />
              {errors.email && (
                <span>Vänligen ange en giltig e-postadress</span>
              )}
            </div>
            <div>
              <p>Telefon</p>
              <input aria-label="phone-number" {...register("phone")} />
            </div>
          </div>

          <div className="contact-form-message">
            <p>Meddelande*</p>
            <textarea
              aria-label="message"
              placeholder="Skriv ditt meddelande här"
              {...register("message", { required: true })}
            />
            {errors.message && <span>Meddelandet kan inte vara tomt</span>}
          </div>
          <br />
          <p>Fält markerade med * är obligatoriska</p>
          <br />
          <div className="contact-form-checkbox">
            <input
              aria-label="terms-checkbox"
              type="checkbox"
              {...register("checkbox", { required: true })}
            />
            <p>
              Jag har läst och accepterar{" "}
              <a
                href="/anvandarvillkor"
                target="_blank"
                rel="noopener noreferrer"
              >
                Användarvillkor
              </a>
            </p>
            <br />
            {errors.checkbox && <span>Du måste acceptera villkoren</span>}
          </div>
          <div className="contact-form-send-button-container">
            {buttonClicked ? (
              <p>Tack för ditt meddelande!</p>
            ) : (
              <RoundedButton
                className="contact-form-send-button"
                color="green"
                label="Skicka"
                size="md"
              />
            )}
          </div>
        </form>
      </main>
    </>
  );
}

export default ContactU;
