import './momsDropdown.css';
import Checkbox from '../../../../components/Checkbox/Checkbox';
import TextButton from '../../../../components/Buttons/TextButton/TextButton';

export default function MomsDropdown({ selectedMoms, setSelectedMoms }) {

  const handleCheckboxChange = (value) => {
    if (selectedMoms.includes(value)) {
      setSelectedMoms(selectedMoms.filter(item => item !== value));
    } else {
      setSelectedMoms([...selectedMoms, value]);
    }
  };

  const handleClearButtonClick = () => {
    setSelectedMoms([]);
    sessionStorage.removeItem('selectedMoms');
  };


  return (
    <div className={`moms-dropdown`}>
      <p>Momsad lokal?</p>
      <div className='moms-content-wrapper'>
        <div className='moms-checkbox-wrapper'>
          <Checkbox label='Momsad' isChecked={selectedMoms.includes("true")} onChange={() => handleCheckboxChange("true")} />
          <Checkbox label='Ej momsad' isChecked={selectedMoms.includes("false")} onChange={() => handleCheckboxChange("false")} />
        </div>
      </div>
      <hr />
      <div className='moms-button-wrapper'>
        <TextButton label='Rensa' onClick={handleClearButtonClick} />
      </div>
    </div>
  );
}