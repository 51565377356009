import React from "react";
import RoundedLinkButton from "../../../components/Buttons/RoundedLinkButton/RoundedLinkButton";
import './latestNews.css'

export const LatestNews = () => {
  return (
    <div className="latest-news">
      <h3 className="latest-news-title">Senaste nytt om Vakansa</h3>
      <p>
        Följ vår utveckling och läs det senaste om oss i vår nya blogg! Här kan
        du hitta information om branschen i stort, 
        <br/>
        om hur det ser ut på
        lokalmarknaden och hur hållbarhetsfrågorna tangerar det vi gör.
      </p>
      <br/>
      <p>
      Det brukar sägas att bygg- och fastighetsbranschen är samhällets motor och då är det viktigt att hålla koll på vad som sker i branschen. 
      <br/>
      Annars är risken att vi inte bygger en stad för människor och verksamheter, utan människor och verksamheter som anpassar sig för staden.
      </p>
      <RoundedLinkButton
      label={"Gå till bloggen"}
      href={"/blogg"}
      size="md"
      />
    </div>
  );
};
