import "./availableSpacesLinks.css";

const AvailableSpacesLinks = () => {
  const locations = ["Stockholm", "Göteborg", "Malmö", "Uppsala"];
  const spaceTypes = [
    { path: "", label: "Lediga lokaler" },
    { path: "/kontorslokal", label: "Lediga kontorslokaler" },
    {
      path: "/kontorshotell&co-working",
      label: "Lediga kontorshotell/co-working lokaler",
    },
    { path: "/möteslokal", label: "Lediga möteslokaler" },
    { path: "/biograf", label: "Lediga biografer" },
    { path: "/idrott&danslokal", label: "Lediga idrott/danslokaler" },
  ];
  return (
    <section
      className="available-spaces-links-section"
      aria-label="Available spaces directory"
    >
      <nav className="available-spaces-nav">
        <div className="available-spaces-content">
          {locations.map((location) => (
            <div key={location} className="available-spaces-group">
              <div className="links-header" role="heading" aria-level="2">
                {location}
              </div>
              <ul className="links-list">
                {spaceTypes.map((type) => (
                  <li key={`${location}-${type.path}`} className="links-item">
                    <a
                      href={`/lediga-lokaler/${location.toLowerCase()}${
                        type.path
                      }`}
                      className="links-anchor"
                    >
                      {type.label} i {location}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </nav>
    </section>
  );
};

export default AvailableSpacesLinks;
