import React, { useState } from "react";
import './spaceDropdown.css'
import Checkbox from "../../../../components/Checkbox/Checkbox"
import TextButton from "../../../../components/Buttons/TextButton/TextButton";
import RoomTypesModal from './RoomTypesModal/RoomTypesModal';

export default function SpaceDropdown({ selectedRoomTypes, setSelectedRoomTypes }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleTypeChange = (type) => {
    if (selectedRoomTypes.includes(type)) {
      setSelectedRoomTypes(selectedRoomTypes.filter(item => item !== type));
    } else {
      setSelectedRoomTypes([...selectedRoomTypes, type]);
    }
  }

  const handleClearButtonClick = () => {
    setSelectedRoomTypes([])
    sessionStorage.removeItem('selectedRoomTypes');
  }

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  return (
    <div className={`space-dropdown`}>
      <p className="space-dropdown-top-text">Typ av lokal</p>
      <div className='space-checkbox-wrapper'>
        <div className="space-checkbox-inner-wrapper">
          <Checkbox label='Kontorslokal' isChecked={selectedRoomTypes.includes('kontorslokal')} onChange={() => handleTypeChange('kontorslokal')} />
          <Checkbox label='Kontorshotell/Co-working' isChecked={selectedRoomTypes.includes('kontorshotell&co-working')} onChange={() => handleTypeChange('kontorshotell&co-working')} />
          <Checkbox label='Möteslokal' isChecked={selectedRoomTypes.includes('möteslokal')} onChange={() => handleTypeChange('möteslokal')} />
          <Checkbox label='Idrott/danslokal' isChecked={selectedRoomTypes.includes('idrott&danslokal')} onChange={() => handleTypeChange('idrott&danslokal')} />
          <Checkbox label='Eventlokal' isChecked={selectedRoomTypes.includes('eventlokal')} onChange={() => handleTypeChange('eventlokal')} />
        </div>
        <div className="show-more-wrapper">
          <p className="space-dropdown-show-more" onClick={handleOpenModal}>Visa mer lokaler</p>
        </div>
        {isModalOpen && (
          <RoomTypesModal
            isOpen={isModalOpen}
            onRequestClose={() => setIsModalOpen(false)}
            selectedRoomTypes={selectedRoomTypes} setSelectedRoomTypes={setSelectedRoomTypes}
          />
        )}
      </div>
      <hr />
      <div className='space-button-wrapper'>
        <TextButton label='Rensa' onClick={handleClearButtonClick} />
      </div>
    </div>
  )
}