import React, { useEffect, useState } from "react";
import axios from "axios";
import { useAuth } from "../../context/AuthContext";
import { useParams } from "react-router-dom";
import { backend } from "../../backend";
import { logAxiosError } from "../../logging/error";
import RoundedButton from "../Buttons/RoundedButton/RoundedButton";
import "./verifyUserModal.css"

function VerifyUserModal({ setShowModal }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [successMessage, setSuccessMessage] = useState("Verifierar...");
  const [errorMessage, setErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isProcessed, setIsProcessed] = useState(false); 
  const { userId } = useParams();
  const auth = useAuth();

  useEffect(() => {
    if (isProcessed) return; 
  
    const verifyUser = async () => {
      setSuccessMessage("");
      setErrorMessage("");
  
      try {
        const { data } = await axios.get(backend("/api/users/account/activate"), {
          params: { user_id: userId },
        });
  
        if (data.status) {
          setSuccessMessage(
            "Ditt konto är nu aktiverat, du kan nu logga in och börja använda tjänsten!"
          );
        } else {
          setErrorMessage(
            "Kunde inte verifiera din e-postadress, försök klicka länken i aktiveringssmejlet en gång till."
          );
        }
  
        setIsProcessed(true); 
      } catch (error) {
        logAxiosError(error);
        setErrorMessage(
          "Kunde inte verifiera din e-postadress, försök klicka länken i aktiveringssmejlet en gång till."
        );
        setIsProcessed(true); 
      }
    };
  
    setTimeout(verifyUser, 500);
  }, [userId, isProcessed]);

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    setSuccessMessage("");
    setErrorMessage("");

    try {
      const { data } = await axios.post(backend("/api/users/login"), {
        email: email.toLowerCase(),
        password,
      });

      if (data.status) {
        setSuccessMessage(data.msg);
        auth.login(data.token, data.user);
        setTimeout(onCloseHandler, 500);
      }
    } catch (error) {
      logAxiosError(error);
      setErrorMessage(error.response?.data?.msg);
    }
  };

  const onCloseHandler = () => {
    setEmail("");
    setPassword("");
    setSuccessMessage("");
    setErrorMessage("");
    setShowModal(false);
  };

  return (
    <>
      <div className="verify-user-modal-component">
        <h1>Välkommen till Vakansa</h1>
        {successMessage && <p id="verify-modal-success">{successMessage}</p>}
        {errorMessage && <p id="verify-modal-error">{errorMessage}</p>}
        <form onSubmit={onSubmitHandler}>
          <label htmlFor="verify-modal-email">E-postadress</label>
          <input
            type="text"
            id="verify-modal-email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <label htmlFor="verify-modal-password">Lösenord</label>
          <div className="verify-form-show-password-div">
            <input
              type={showPassword ? "text" : "password"}
              id="verify-modal-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <img className="login-form-show-password" onClick={() => setShowPassword(!showPassword)} src={`/images/icons/eye_${showPassword ? "visible" : "hidden"}.svg`} alt="Show password" />
          </div>
          <div id="verify-modal-forgot-password">
            <a href="/glomt-losenord/e-post">Glömt ditt lösenord?</a>
          </div>
          <div id="verify-modal-form-button">
            <RoundedButton label={"Logga in"}></RoundedButton>
          </div>
        </form>
        <p>
          Läs om hur vi hanterar personlig information i våra{" "}
          <a href="/anvandarvillkor" target="_blank" rel="noopener noreferrer">Användarvillkor</a>.
        </p>
        <button id="verify-modal-close-button" onClick={onCloseHandler}>
          <img src="/images/icons/close.svg" alt="close-icon-button" />
        </button>
      </div>
      <div className="verify-user-modal-backdrop" onClick={onCloseHandler} />
    </>
  );
}

export default VerifyUserModal;
