import React, { useState } from "react";
import axios from "axios";
import { backend } from "../../../backend";
import { logAxiosError } from "../../../logging/error";
import ConfirmationModal from "../../../components/ConfirmationModal/ConfirmationModal";
import "./resendValidation.css"
import { useForm } from "react-hook-form";

function ResendValidation({ serverError, setServerError }) {
  const [showEmailModal, setShowEmailModal] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = async (formData) => {
    try {
      await handleFormSubmit(formData);
      showEmailModal(true);
    } catch (error) {
      console.error(error);
    }
  };

  const handleFormSubmit = async (formData) => {
    setServerError("");
    try {
      const { data } = await axios.post(
        backend("/api/users/resend/activation/email"),
        formData
      );

      if (data.status) {
        setShowEmailModal(true);
      }
    } catch (error) {
      logAxiosError(error);
      setServerError(error.response?.data?.msg);
    }
  };

  return (
    <div className="resend-validation-outer">
      <div className="resend-validation-component">
        <h1>Hittar du inte valideringsmejlet?</h1>
        <form onSubmit={handleSubmit(onSubmit)}>
          <label htmlFor="forgot-password-email">
            Begär ett nytt valideringsmejl genom att fylla i e-postadressen du
            angav vid skapandet av kontot
          </label>
          <input
            data-testid="resend-email-input"
            type="email"
            {...register("email", { required: true })}
          />
          {serverError ? (
            <p
              style={{ color: "red" }}
              data-testid="missing-email-error"
              id="forgot-password-server-error-msg"
            >
              {serverError}
            </p>
          ) : (
            errors.email && (
              <p style={{ color: "red" }} data-testid="empty-email-error">
                Vänligen ange en giltig e-postadress
              </p>
            )
          )}
          <div>
            <button data-testid="resend-validation-btn">Fortsätt</button>
          </div>
        </form>
        <p>
          Läs om hur vi hanterar personlig information i våra{" "}
          <a href="/anvandarvillkor" target="_blank" rel="noopener noreferrer">
            Användarvillkor
          </a>
          .
        </p>
      </div>
      {showEmailModal && (
        <ConfirmationModal
          heading="Skickat nytt aktiveringsmejl till din inkorg"
          text="Om epostadressen är registrerad hos oss kommer ett mejl skickas till epostadressen.
          Kolla din skräpkorg om du inte hittar mejlet."
          setShowModal={() => setShowEmailModal(false)}
        />
      )}
    </div>
  );
}

export default ResendValidation;
