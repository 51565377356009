import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

const getStoredFilters = (filterType, defaultValue) => {
  const storedFilters = sessionStorage.getItem(filterType);
  return storedFilters ? JSON.parse(storedFilters) : defaultValue;
};

const useFilters = (location) => {
  const params = new URLSearchParams(location.search);
  const { municipality: pathMunicipality, type_of_room: pathTypeOfRoom } =
    useParams();

  const [searchTerm, setSearchTerm] = useState(params.get("q") || "");

  const [selectedMunicipality, setSelectedMunicipality] = useState(() => {
    return pathMunicipality ? pathMunicipality.split("-") : [];
  });

  const [selectedRoomTypes, setSelectedRoomTypes] = useState(() => {
    return pathTypeOfRoom ? pathTypeOfRoom.split("-") : [];
  });

  const [selectedTime, setSelectedTime] = useState(() => {
    return params.get("pristyp")
      ? params.get("pristyp").split(",")
      : getStoredFilters("selectedTime", []);
  });

  const [selectedPriceRange, setSelectedPriceRange] = useState(() => {
    const min = params.get("min-hyra");
    const max = params.get("max-hyra");
    if (min || max) {
      return {
        min: min ? parseInt(min, 10) : null,
        max: max ? parseInt(max, 10) : null,
      };
    }
    return getStoredFilters("selectedPriceRange", { min: null, max: null });
  });

  const [selectedAreaRange, setSelectedAreaRange] = useState(() => {
    const min = params.get("min-yta");
    const max = params.get("max-yta");
    if (min || max) {
      return {
        min: min ? parseInt(min, 10) : null,
        max: max ? parseInt(max, 10) : null,
      };
    }
    return getStoredFilters("selectedAreaRange", { min: null, max: null });
  });

  const [selectedMoms, setSelectedMoms] = useState(() => {
    const moms = params.get("moms");
    return moms === "true" ? ["true"] : moms === "false" ? ["false"] : [];
  });

  useEffect(() => {
    sessionStorage.setItem("selectedTime", JSON.stringify(selectedTime));
    sessionStorage.setItem(
      "selectedRoomTypes",
      JSON.stringify(selectedRoomTypes)
    );
    sessionStorage.setItem(
      "selectedMunicipality",
      JSON.stringify(selectedMunicipality)
    );
    sessionStorage.setItem(
      "selectedPriceRange",
      JSON.stringify(selectedPriceRange)
    );
    sessionStorage.setItem(
      "selectedAreaRange",
      JSON.stringify(selectedAreaRange)
    );
    sessionStorage.setItem("selectedMoms", JSON.stringify(selectedMoms));
  }, [
    selectedTime,
    selectedRoomTypes,
    selectedMunicipality,
    selectedPriceRange,
    selectedAreaRange,
    selectedMoms,
  ]);

  const resetFilters = () => {
    setSelectedPriceRange({ min: null, max: null });
    setSelectedAreaRange({ min: null, max: null });
    setSelectedTime([]);
    setSelectedRoomTypes([]);
    setSelectedMunicipality([]);
    setSelectedMoms([]);
    setSearchTerm("");
  };

  const areFiltersActive = () => {
    return (
      selectedMunicipality.length > 0 ||
      selectedRoomTypes.length > 0 ||
      selectedTime.length > 0 ||
      selectedAreaRange.min !== null ||
      selectedAreaRange.max !== null ||
      selectedPriceRange.min !== null ||
      selectedPriceRange.max !== null ||
      selectedMoms.length > 0 ||
      searchTerm.trim().length > 0
    );
  };

  return {
    searchTerm,
    selectedMunicipality,
    selectedRoomTypes,
    selectedTime,
    selectedPriceRange,
    selectedAreaRange,
    selectedMoms,
    setSearchTerm,
    setSelectedMunicipality,
    setSelectedRoomTypes,
    setSelectedTime,
    setSelectedPriceRange,
    setSelectedAreaRange,
    setSelectedMoms,
    resetFilters,
    areFiltersActive,
  };
};

export default useFilters;
