import React from "react";
import "./checkbox.css";

export default function Checkbox({
  label,
  multilineLabel,
  isChecked,
  onChange,
}) {
  const handleCheckboxChange = () => {
    onChange(!isChecked);
  };

  return (
    <div className="checkbox-container">
      <label className="checkbox-wrapper">
        <input
          className="checkbox-input"
          type="checkbox"
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
        <span className="checkbox-label">
          {multilineLabel ? (
            <>
              <span>{multilineLabel.split("/")[0].trim()}</span>
              <br />
              <span>{multilineLabel.split("/")[1].trim()}</span>
            </>
          ) : (
            label
          )}
        </span>
      </label>
    </div>
  );
}
