import { useState, useEffect } from "react";
import "./noAdResults.css";
import AdCard from "../AdCard/AdCard";
import RoundedLinkButton from "../../../components/Buttons/RoundedLinkButton/RoundedLinkButton";
import { fetchFilteredAds } from "../api/AdPageAPI";
import Spinner from "../../../components/Spinner/Spinner";
import { logAxiosError } from "../../../logging/error";

export default function NoResults() {
  const [latestAds, setLatestAds] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const fetchLatestAds = async () => {
      try {
        await fetchFilteredAds({
          filterParams: {},
          currentPage: 1,
          adsPerPage: 3,
          sortingOption: "",
          setId: setLatestAds,
          setTotalPages: () => {},
          setTotalAds: () => {},
          searchTerm: "",
          setAllAds: () => {},
          setIsLoading,
        });
      } catch (error) {
        logAxiosError(error);
        setErrorMessage("Kunde inte ladda annonser. Försök igen.");
        setIsLoading(false);
      }
    };

    fetchLatestAds();
  }, []);

  if (isLoading) {
    return (
      <div className="no-results-ad-section">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="no-results-ad-section">
      <div className="no-card-outer-link">
        <article className={`no-search-result-card`}>
          <div className="no-card-image">
            <div className="no-card-image-text">
              <p>Kan vi inte erbjuda dig din drömlokal just nu?</p>
              <p>Ingen fara!</p>
            </div>
          </div>
          <div className="search-no-card">
            <p>
              Skicka in en lokalförfrågan så letar vi upp en lokal åt er -
              kostnadsfritt!
            </p>
            <RoundedLinkButton
              size="lg"
              label="Få gratis lokalförslag"
              href={"/lokalforslag"}
            />
          </div>
        </article>
      </div>
      <h2 className="no-ads-h2">Liknande annonser nära ditt sökområde</h2>
      {errorMessage ? (
        <p className="no-ads-errormsg">{errorMessage}</p>
      ) : latestAds.length > 0 ? (
        <div className="no-ads-container">
          {latestAds.map((ad) => (
            <div className="no-ads-generated-ads" key={ad._id}>
              <AdCard id={ad} />
            </div>
          ))}
        </div>
      ) : (
        <p className="no-ads-found">Inga annonser hittades.</p>
      )}
    </div>
  );
}
