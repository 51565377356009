/**
 * Check whether all required fields are valid. If so, returns true
 * @param {object} formData - the object containing formData
 * @param {array} newImages - array containing new image objects
 */
export const requiredFieldsCheck = (formData, newImages) => {
  const timeslots = formData?.fully_vacant || Object.values(formData?.timeslots).flat().length;
  // const imageCount = newImages.length + formData?.images.length;
  // const images = imageCount >= 3 && imageCount <= 12;
  const rent =
    formData?.hourly_rent ||
    formData?.daily_rent ||
    formData?.workspace_rent ||
    formData?.monthly_rent;

  return (
    formData?.owner_name &&
    formData?.address &&
    formData?.city &&
    formData?.municipality &&
    formData?.zip_code &&
    formData?.type_of_room &&
    formData?.area &&
    formData?.description &&
    timeslots &&
    // images &&
    rent
  );
};

/**
 *  Authenticate all required fields. And toggle their error messages. returns boolean
 * @param {object} formData - the object containing formData
 * @param {array} newImages - array containing new image objects
 * @param setInvalidFields - useState set function that expects a array of field names
 */
export const authenticateForm = (formData, newImages, setInvalidFields) => {
  if (requiredFieldsCheck(formData, newImages)) {
    return true; // Authentication passed
  } else {
    const requiredFields = [
      "owner_name",
      "address",
      "city",
      "municipality",
      "zip_code",
      "type_of_room",
      "area",
      "description",
    ];

    let invalidFields = requiredFields.filter(
      (field) => !formData?.[field] || formData?.[field].length === 0
    );

    // // require min 4 and max 12 images 
    // const imageCount = newImages?.length + formData?.images.length;
    // if (imageCount < 3 || imageCount > 12) {
    //   invalidFields.push("images");
    // }

    if (
      // At least 1 price needs to be specified
      !formData?.hourly_rent &&
      !formData?.daily_rent &&
      !formData?.workspace_rent &&
      !formData?.monthly_rent
    ) {
      invalidFields.push("rent");
    }


    return false; // Authentication failed
  }
};
