import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';

const debounce = (func, delay) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => func(...args), delay);
  };
};


const useDebouncedNavigate = () => {
  const navigate = useNavigate();


  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(
    debounce((url) => {
      if (url) {
        navigate(url, { replace: true });
      } else {
        console.error("URL is undefined");
      }
    }, 300), 
    [navigate]
  );
};

export default useDebouncedNavigate;
