import React, { useState} from "react";
import axios from "axios";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { backend } from "../../backend";
import { logAxiosError } from "../../logging/error";
import "./loginPage.css";
import RoundedButton from "../../components/Buttons/RoundedButton/RoundedButton";

function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const auth = useAuth();

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    setSuccessMessage("");
    setErrorMessage("");

    if (!email.trim() || !password.trim()) {
      setErrorMessage("E-postadress eller lösenord saknas.");
      return;
    }

    try {
      const { data } = await axios.post(backend("/api/users/login"), {
        email: email.toLowerCase(),
        password,
      });
      if (data.status) {
        auth.login(data.token, data.user);
        setSuccessMessage(data.msg);
        setTimeout(onCloseHandler, 500);
      } else {
        setErrorMessage(data.msg);
      }
    } catch (error) {
      logAxiosError(error);
      setErrorMessage(error.response?.data?.msg);
    }
  };

  const onCloseHandler = () => {
    setEmail("");
    setPassword("");
    setSuccessMessage("");
    setErrorMessage("");
    
  };

  const onCreateAccountHandler = () => {
    onCloseHandler();
    navigate("/registrera-konto");
  };


  return  (
    <div role="dialog" aria-label="sign-in-page" className="login-page-outer">
      <div className="login-page">
        <h1>Välkommen till Vakansa</h1>
        <p id="login-page-instructions">
          Ange din e-postadress och lösenord för att logga in.
        </p>
        {successMessage && <p id="login-page-success">{successMessage}</p>}
        {errorMessage && <p id="login-page-error">{errorMessage}</p>}
        <form onSubmit={onSubmitHandler} id="login-page-form">
          <label htmlFor="login-page-email">E-postadress</label>
          <input
            aria-label="email"
            type="text"
            id="login-page-email"
            data-testid="email-inputfield"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <label htmlFor="login-page-password">Lösenord</label>
          <div className="login-password-div">
            <input
              aria-label="password"
              type={showPassword ? "text" : "password"}
              id="login-page-password"
              data-testid="password-inputfield"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <img
              className="login-form-show-password"
              onClick={() => setShowPassword(!showPassword)}
              src={`/images/icons/eye_${
                showPassword ? "visible" : "hidden"
              }.svg`}
              alt="Show password"
            />
          </div>
          <div id="login-page-forgot-password">
            <a data-testid="forgot-pw-link" href="/glomt-losenord/e-post">
              Glömt ditt lösenord?
            </a>
            <a data-testid="resend-email-link" href="/skicka-validering">
              Hittar inte valideringsmejlet?
            </a>
          </div>
        </form>
        <div id="login-page-buttons">
          <button
            aria-label="sign-in"
            data-testid="page-login-btn"
            type="submit"
            form="login-page-form"
          >
            Logga in
          </button>
          <RoundedButton
            onClick={onCreateAccountHandler}
            color="white"
            label="Skapa konto"
            size="md"
            aria-label="create-account"
          />
        </div>
        <p>
          Läs om hur vi hanterar personlig information i våra {" "}
          <a href="/anvandarvillkor" target="_blank" rel="noopener noreferrer">
            Användarvillkor
          </a>
          .
        </p>
       
      </div>
    </div>
  ) 
}

export default LoginPage;
