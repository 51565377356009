import "./timeDropdown.css";
import Checkbox from "../../../../components/Checkbox/Checkbox";
import TextButton from "../../../../components/Buttons/TextButton/TextButton";

export default function TimeDropdown({
  selectedTime,
  setSelectedTime,
}) {

  const handleTimeChange = (time) => {
    if (selectedTime.includes(time)) {
      setSelectedTime(selectedTime.filter((item) => item !== time));
    } else {
      setSelectedTime([...selectedTime, time]);
    }
  };

  const handleClearButtonClick = () => {
    setSelectedTime([]);
    sessionStorage.removeItem("selectedTime");

  };


  return (
    <div className={`time-dropdown`}>
      <p>Hur vill du hyra?</p>
      <div className="time-content-wrapper">
        <div className="time-checkbox-wrapper">
          <Checkbox
            label="Timvis"
            isChecked={selectedTime.includes("timvis")}
            onChange={() => handleTimeChange("timvis")}
          />
          <Checkbox
            label="Dagsvis"
            isChecked={selectedTime.includes("dagsvis")}
            onChange={() => handleTimeChange("dagsvis")}
          />
          <Checkbox
            label="Månadsvis"
            isChecked={selectedTime.includes("månadsvis")}
            onChange={() => handleTimeChange("månadsvis")}
          />
          <Checkbox
            label="Arbetsplats/månad"
            isChecked={selectedTime.includes("per arbetsplats")}
            onChange={() => handleTimeChange("per arbetsplats")}
          />
        </div>
      </div>
      <hr />
      <div className="time-button-wrapper">
        <TextButton label="Rensa" onClick={handleClearButtonClick} />
      </div>
    </div>
  );
}