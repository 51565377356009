import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import DOMPurify from "dompurify";
import { createSEOUrl } from "../../../../utils/createSEOUrl";
import { useLocation } from "react-router-dom";
import "./mostRecent.css";
import Spinner from '../../../../components/Spinner/Spinner';

const GET_PAGES = gql`
  {
    blogposts {
      nodes {
        id
        title
        content
        date
      }
    }
  }
`;

export default function MostRecent() {
  const { loading, error, data } = useQuery(GET_PAGES);
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  if (loading)
    return (
      <div className="most-recent-spinner-wrapper">
        <Spinner />
      </div>
    );
  if (error) return <p>Ett fel uppstod, vänligen försök igen senare.</p>;

  const posts = data.blogposts.nodes;

  const sanitizedPosts = posts.map((post) => ({
    id: DOMPurify.sanitize(post.id),
    title: DOMPurify.sanitize(post.title),
    content: DOMPurify.sanitize(post.content),
    date: DOMPurify.sanitize(post.date),
  }));

  return (
    <div className="most-recent-outer">
      <div className="most-recent-container">
        <h3>Senaste inläggen</h3>
        <ul>
          {sanitizedPosts.length > 0 ? (
            sanitizedPosts.map((blogposts) => (
              <li key={blogposts.id}>
                <Link
                  className="most-recent-item"
                  to={`/blogg/${createSEOUrl(blogposts.title)}`}
                  state={{
                    content: blogposts.content,
                    title: blogposts.title,
                    date: blogposts.date,
                  }}
                >
                  <p className="most-recent-title">{blogposts.title}</p>
                </Link>
              </li>
            ))
          ) : (
            <p>Inga blogginlägg att visa just nu.</p>
          )}
        </ul>
      </div>
    </div>
  );
}
