import React, { useEffect, useState } from "react";
import "./areaDropdown.css";
import TextButton from "../../../../components/Buttons/TextButton/TextButton";

export default function AreaDropdown({
  selectedAreaRange,
  setSelectedAreaRange,
}) {
  const [minArea, setMinArea] = useState(selectedAreaRange.min || "");
  const [maxArea, setMaxArea] = useState(selectedAreaRange.max || "");

  const areaOptions = [
    20, 40, 60, 100, 150, 200, 300, 400, 500, 750, 1000, 1500, 2000,
  ];

  useEffect(() => {
    setMinArea(selectedAreaRange.min || "");
    setMaxArea(selectedAreaRange.max || "");
  }, [selectedAreaRange]);

  const handleMinInputChange = (event) => {
    const value = event.target.value;
    setMinArea(value);
    handleAreaRangeChange({
      min: value,
      max: maxArea,
    });
  };

  const handleMaxInputChange = (event) => {
    const value = event.target.value;
    setMaxArea(value);
    handleAreaRangeChange({
      min: minArea,
      max: value,
    });
  };

  const handleAreaRangeChange = ({ min, max }) => {
    setSelectedAreaRange({ min, max });
    sessionStorage.setItem("selectedAreaRange", JSON.stringify({ min, max }));
  };

  const handleClearButtonClick = () => {
    setSelectedAreaRange({ min: null, max: null });
    setMinArea("");
    setMaxArea("");
    sessionStorage.removeItem("selectedAreaRange");
  };

  return (
    <div className="area-dropdown">
      <p>Yta (m2)</p>
      <div className="area-slider">
        <div className="area-slider-container">
          <div className="area-slider-area">
            <div className="area-slider-min-max">
              <select value={minArea} onChange={handleMinInputChange}>
                <option value="">Min yta (m2) </option>
                {areaOptions.map((area) => (
                  <option
                    key={area}
                    value={area}
                    disabled={maxArea && area >= maxArea}
                  >
                    {area}
                  </option>
                ))}
              </select>
              <select value={maxArea} onChange={handleMaxInputChange}>
                <option value="">Max yta (m2)</option>
                {areaOptions.map((area) => (
                  <option
                    key={area}
                    value={area}
                    disabled={minArea !== "" && area <= minArea}
                  >
                    {area}
                  </option>
                ))}
              </select>
            </div>
            <hr />
            <div className="area-button-wrapper">
              <TextButton label="Rensa" onClick={handleClearButtonClick} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
