import React from "react";
import "./sustainability.css";
import stairs from "../../assets/images/trappa.png";
import SEOTags from "../../utils/SEOTags";

export default function Sustainability() {
  return (
    <div className="sustainability-outer">
      <SEOTags
        title={"Hållbarhet   "}
        description={
          "Vakansa matchar dig med lokaler som är skräddarsydda efter dina specifika behov och önskemål!"
        }
        canonicalUrl={"/hallbarhet"}
        ogImage={"/images/logo/meta-logo.jpg"}
      />
      <div className="sustainability-inner">
        <main className="sustainability-container">
          <div className="sustainability-intro-content">
            <div className="sustainability-intro-text">
              <h1>Hållbarhet</h1>
              <h2>Vakansas bidrag till en hållbar omställning</h2>
              <p>
                I en tid då hållbarhet och effektiv resursanvändning är centrala
                frågor för samhällsutvecklingen, spelar fastighetsbranschen en
                avgörande roll. Städer utgör enbart 3% av jordens landyta men
                använder 60-80% av all energi som används, släpper ut 75% av
                utsläppen(
                <a href="https://www.un.org/sustainabledevelopment/cities/">
                  Källa: FN
                </a>
                ) och står för cirka 50% av all råvaruuttag i världen (
                <a href="https://www.industrytransition.org/insights/towards-a-sustainable-global-construction-and-buildings-value-chain/">
                  Källa: EU
                </a>
                ).
              </p>
              <br />
              <p>
                I en värld där urbaniseringen antas öka markant de kommande åren
                och decennierna blir behovet av att optimera användningen av
                befintliga resurser allt viktigare. Därför har vi tagit fram
                nedan modell, som vi kallar för Fastighetstrappan. En trappa som
                visar vart vi är idag inom fastighetsbranschen och vilka steg
                som kan tas upp för den mot en hållbar omställning.
              </p>
              <br />
              <div className="sustainability-img">
                <img
                  loading="lazy"
                  className="stairs-image"
                  src={stairs}
                  alt="trappa eller något"
                />
              </div>

              <br />

              <ol type="1">
                <li>
                  <b>Riva befintligt och/eller bygga nytt av råvaror:</b> Vi kan
                  inte fortsätta riva för att bygga nytt och bygga nytt för att
                  riva - för att vi inte gillar byggnader eller för att de inte
                  genererar tillräckligt mycket med intäkter. Välfungerande
                  byggnader får absolut inte rivas och det är därför som Vakansa
                  skrivit på kampanjen “Krama byggnaden” som vår grundare
                  initierade(
                  <a href="https://www.mittskifte.org/petitions/krama-byggnaden">
                    Krama byggnaden!
                  </a>
                  ) år 2022.
                </li>

                <li>
                  <b>Bygga hus av hus:</b> Vi kan demontera byggnader och
                  använda byggnadsdelarna till nya eller befintliga byggnader
                  för att minimera avfallet från rivningar av uttjänta
                  byggnader. Här jobbar vår grundare deltid i
                  forskningsprojektet “Återhus - att bygga hus av hus” (
                  <a href="https://aterhus.nu/">Återhus</a>
                  ).
                </li>

                <li>
                  <b>Bygga på, bygga om, bygga till eller flytta byggnader:</b>{" "}
                  Ska resurskrävande ingrepp göras på byggnader ska det helst av
                  allt handla om att bygga på, bygga om, bygga till befintliga
                  byggnader eller göras insatser för att flytta dem i sin
                  helhet. Ju mindre resurskrävande insatser, desto bättre!
                </li>

                <li>
                  <b>
                    Underhålla byggnader och förvalta för resurseffektivitet:
                  </b>
                  Den absolut bästa åtgärden är att ta hand om de byggnader som
                  redan finns och sträva efter “evighetsförvaltning” som
                  Stockholms Kooperativa Bostadsbolag kallar det för (
                  <a href="https://www.skb.org/vad-ar-evighetsforvaltning/">
                    Vad är evighetsförvaltning? | SKB
                  </a>
                  ) i syfte att jobba långsiktigt med fastighetsförvaltning och
                  underhållsarbetet. Ibland kräver det dock nya hållbara
                  affärsmodeller och lösningar när det kommer till
                  lokaluthyrning där befintliga byggnader även används över
                  dygnets vakna timmar, istället för enbart 10% av tiden som med
                  fallet med kontor och 16% av tiden när det kommer till skolor
                  - Ju mer byggnader används, desto mindre blir behovet av att
                  bygga nytt. (
                  <a href="https://www.iva.se/publicerat/rapport-resurseffektiva-lokaler-i-sverige/">
                    Rapport: Resurseffektiva lokaler i Sverige
                  </a>
                  ).
                </li>

                <li>
                  <b>
                    Den mest hållbara byggnaden är den som aldrig behöver
                    byggas:
                  </b>{" "}
                  I vår del av världen, där det finns ett överflöd av
                  kvadratmeter, behöver vi tänka om kring hur vi använder de
                  kvadratmeter som byggts. Inte att bygga fler kvadratmeter. Det
                  är först när bygg- och fastighetsbranschen inser att “den mest
                  hållbara byggnaden är den som aldrig behöver byggas”, som all
                  kraft kommer läggas på att “den mest hållbara byggnaden ska
                  bli den som redan står”. Detta är något som rapporterna
                  Exponential roadmap (
                  <a href="https://exponentialroadmap.org/exponential-roadmap/">
                    Exponential Roadmap
                  </a>
                  ) och Circularity gap report (
                  <a href="https://www.circularity-gap.world/2022">CGR 2022</a>)
                </li>
              </ol>
              <br />
              <p>
                Det är därför Vakansa AB grundades 2019 och har efter pandemin
                snabbt etablerat sig som en nyckelaktör inom delningsekonomi för
                fastigheter i Sverige. Genom sin plattform,{" "}
                <a href="http://Vakansa.se">Vakansa.se</a>, möjliggör Vakansa
                för fastighetsägare och hyresgäster att maximera utnyttjandet av
                kommersiella och öppna ytor. Det unika med Vakansa är vårt fokus
                på "tidsvakanser", det vill säga de tider då lokaler står
                oanvända av förstahandshyresgästen och där plattformen tillåter
                förstahandshyresgäster att hyra ut sina lokaler under dessa
                perioder. Vilket ökar lokalernas användningsgrad och kan minska
                behovet av nybyggnation. Vilket i sin tur leder till minskad och
                fördelad resursförbrukning, avfallsgenerering och utsläpp.
              </p>
              <br />
              <p>
                Vakansas plattform erbjuder även omfattande kringtjänster som
                rör allt från avtalshantering, nyckelhantering, bokningsysstem,
                hjälp med deposition, försäkring till stöd med administrativa
                uppgifter, vilket underlättar för både första- och
                andrahandshyresgäster att minimera risker och hantering av
                andrahandsuthyrning. Detta bryter ner de traditionella
                barriärerna för uthyrning och öppnar upp marknaden för fler
                aktörer, vilket skapar en mer dynamisk och flexibel
                fastighetsmarknad.
              </p>
              <br />
              <p>
                Genom att erbjuda flexibla lösningar för lokaluthyrning och
                delning möjliggör{" "}
                <a href="https://www.vakansa.se/">Vakansa.se</a> också för
                företag och organisationer att anpassa sig till förändrade behov
                och förutsättningar över tid. Detta är särskilt viktigt i en tid
                av snabb förändring och osäkerhet, där flexibilitet och
                anpassningsförmåga är avgörande för att överleva och blomstra
                som organisation.
              </p>
              <br />
              <p>
                På detta sätt bidrar Vakansa också indirekt till social
                hållbarhet där en mångfald av verksamheter kan ta plats i
                städerna och som bjuder in en mångfald av individer. Där ingår
                föreningslivet, kulturlivet och civilsamhället som självklara
                sektorer som vi vill underlätta för att hitta billiga, flexibla
                och passande lokaler för.
              </p>
              <br />
              <p>
                Lokaler ska användas, inte stå tomma och vårt mål är att ingen
                förening, inget företag och ingen organisation ska behöva stå
                eller falla på grund av en lokal. Alla former av “tidsvakanser”
                kan och ska synliggöras, tillgängliggöras och därmed hyras via{" "}
                <a href="https://www.vakansa.se/">Vakansa.se</a>.
              </p>
              <br />
              <p>
                Ytterligare en viktig aspekt av Vakansas arbete är vårt ständiga
                bidrag till regelverk, standarder och aktivt bidra till forum
                inom hållbar stadsutveckling och förvaltning. Vi deltar idag i
                forum såsom{" "}
                <a href="https://viablecities.se/om/medlemmar/">
                  Viable Cities
                </a>
                , <a href="https://www.shiftsweden.se/">ShiftSweden</a> och{" "}
                <a href="https://byggforetagen.se/fossilfri-konkurrenskraft/vi-ar-med/">
                  Färdplan för bygg och anläggning
                </a>{" "}
                för bygg och anläggning, driver Vakansa på för strukturella
                förändringar inom branschen. Vårt arbete hjälper till att forma
                en framtida fastighetsmarknad där delning och effektiv
                resursanvändning står i centrum.
              </p>
              <br />
              <p>
                Sammanfattningsvis representerar Vakansa en katalysator för
                förändring i fastighetsbranschen genom att effektivt koppla
                samman teknologi med hållbarhetsmål. Plattformens förmåga att
                öka lokalanvändningens effektivitet samtidigt som den stöder
                miljömässigt och socialt ansvarstagande affärspraktiker visar på
                den potential som finns i att integrera delningsekonomiska
                principer i traditionella industrier. Vakansas arbete är inte
                bara ett exempel på innovation utan också ett bevis på att
                fastighetsbranschen kan omformas till att vara en del av
                lösningen på dagens klimatutmaningar.
              </p>
              <br />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
