import React from "react";
import importedFacilities from "../../../../assets/facilities.json";
import "./facilitiesForm.css"
const { facilities } = importedFacilities;

function FacilitiesForm({ formData, setFormData }) {
  const isCheckedHandler = (facility) => {
    return formData?.facilities.includes(facility);
  };

  const addFacility = (facility) => {
    setFormData((prevData) => ({
      ...prevData,
      facilities: [...prevData?.facilities, facility],
    }));
  };

  const removeFacility = (facility) => {
    setFormData((prevData) => ({
      ...prevData,
      facilities: prevData?.facilities.filter(
        (current) => current !== facility
      ),
    }));
  };

  const onChangeHandler = (facility) => {
    if (formData?.facilities.includes(facility)) {
      removeFacility(facility);
    } else {
      addFacility(facility);
    }
  };

  return (
    <div className="facilities-form-component">
      <h5>Vad ingår</h5>
      <div className="facilities-checkboxes-container">
        {Object.keys(facilities).map((facility, i) => (
          <div key={facility} className="full-vacancy-checkbox-container">
            <label  className="full-vacancy-checkbox-wrapper">
              <input
                key={i}
                id={`facilities-${facility}`}
                className="full-vacancy-checkbox-input"
                type="checkbox"
                checked={isCheckedHandler(facility)}
                onChange={() => onChangeHandler(facility)}
              />
              <span className="full-vacancy-checkbox-label">{facilities[facility].value}</span>
            </label>
          </div>
        ))}
      </div>
    </div>
  );
}


export default FacilitiesForm;
